import { renderSlot as _renderSlot, withKeys as _withKeys, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "modal-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_click_away = _resolveDirective("click-away")!

  return (_ctx.status)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["overlay", { closing: _ctx.closing }])
      }, [
        _createElementVNode("div", _hoisted_1, [
          _withDirectives((_openBlock(), _createElementBlock("div", {
            class: "content",
            onKeyup: _cache[0] || (_cache[0] = _withKeys(
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)), ["esc"]))
          }, [
            _renderSlot(_ctx.$slots, "content", {}, undefined, true)
          ], 32)), [
            [_directive_click_away, _ctx.close]
          ])
        ])
      ], 2))
    : _createCommentVNode("", true)
}