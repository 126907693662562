import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/images/sliders/background.png'


const _hoisted_1 = { class: "form-row create" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_slider_component = _resolveComponent("slider-component")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_onboarding_layout = _resolveComponent("onboarding-layout")!

  return (_openBlock(), _createBlock(_component_onboarding_layout, { title: "Sign In" }, {
    slider: _withCtx(() => [
      _createVNode(_component_slider_component, {
        class: "slider",
        slider: _ctx.slider,
        pagerPosition: "right"
      }, null, 8, ["slider"]),
      _cache[0] || (_cache[0] = _createElementVNode("img", {
        loading: "lazy",
        src: _imports_0
      }, null, -1))
    ]),
    form: _withCtx(() => [
      _createVNode(_component_router_view),
      _createElementVNode("div", _hoisted_1, [
        _cache[2] || (_cache[2] = _createElementVNode("span", null, "Not registered yet ?", -1)),
        _createVNode(_component_router_link, { to: "/register" }, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [
            _createTextVNode("Create an Account")
          ])),
          _: 1
        })
      ])
    ]),
    _: 1
  }))
}