import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, vShow as _vShow, resolveComponent as _resolveComponent, withKeys as _withKeys } from "vue"
import _imports_0 from '@/assets/images/sprites/common-sprite.svg'


const _hoisted_1 = _imports_0 + '#info-icon'
const _hoisted_2 = _imports_0 + '#calender'
const _hoisted_3 = _imports_0 + '#loading-icon'
const _hoisted_4 = _imports_0 + '#cross-icon'
const _hoisted_5 = {
  key: 0,
  class: "label"
}
const _hoisted_6 = {
  key: 0,
  class: "info",
  "tooltip-right": "Required information"
}
const _hoisted_7 = { key: 1 }
const _hoisted_8 = {
  key: 2,
  class: "items"
}
const _hoisted_9 = ["onClick"]
const _hoisted_10 = ["type", "value", "placeholder", "disabled", "readonly"]
const _hoisted_11 = ["value", "disabled", "readonly"]
const _hoisted_12 = ["xlink:href"]
const _hoisted_13 = {
  key: 5,
  class: "loading"
}
const _hoisted_14 = ["xlink:href"]
const _hoisted_15 = {
  key: 7,
  class: "file-container"
}
const _hoisted_16 = {
  key: 8,
  class: "unit"
}
const _hoisted_17 = ["onClick"]
const _hoisted_18 = { class: "name" }
const _hoisted_19 = {
  key: 0,
  class: "info"
}
const _hoisted_20 = {
  key: 3,
  class: "input-description"
}
const _hoisted_21 = {
  key: 4,
  class: "input-errors"
}
const _hoisted_22 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_datepicker_component = _resolveComponent("datepicker-component")!
  const _directive_maska = _resolveDirective("maska")!
  const _directive_click_away = _resolveDirective("click-away")!

  return _withDirectives((_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["container", { required: _ctx.required }]),
    onKeyup: [
      _cache[11] || (_cache[11] = _withKeys(
//@ts-ignore
(...args) => (_ctx.hideFeature && _ctx.hideFeature(...args)), ["esc"])),
      _cache[12] || (_cache[12] = _withKeys(
//@ts-ignore
(...args) => (_ctx.hideFeature && _ctx.hideFeature(...args)), ["enter"]))
    ]
  }, [
    (_ctx.label || _ctx.labelitems)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          (_ctx.required)
            ? (_openBlock(), _createElementBlock("span", _hoisted_6, _cache[13] || (_cache[13] = [
                _createElementVNode("svg", { class: "required" }, [
                  _createElementVNode("use", { "xlink:href": _hoisted_1 })
                ], -1)
              ])))
            : _createCommentVNode("", true),
          (_ctx.label)
            ? (_openBlock(), _createElementBlock("label", _hoisted_7, _toDisplayString(_ctx.label), 1))
            : _createCommentVNode("", true),
          (_ctx.labelitems)
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.labelitems, (item, index) => {
                  return (_openBlock(), _createElementBlock("span", {
                    key: index,
                    class: _normalizeClass(item.class),
                    onClick: ($event: any) => (_ctx.emitter(item))
                  }, _toDisplayString(item.text), 11, _hoisted_9))
                }), 128))
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("section", {
      class: _normalizeClass({ focus: _ctx.isFocus, error: _ctx.errors && _ctx.errors.length, disabled: _ctx.disabled, money: _ctx.type == 'money' })
    }, [
      (_ctx.type == 'money')
        ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.currencyComponent), {
            key: 0,
            class: "currency no-border",
            onSelectChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('changeCurrency', $event))),
            items: _ctx.currencies,
            value: _ctx.currencyValue || _ctx.user.currency,
            disabled: _ctx.disabled
          }, null, 40, ["items", "value", "disabled"]))
        : _createCommentVNode("", true),
      (_ctx.type == 'datepicker')
        ? (_openBlock(), _createElementBlock("svg", {
            key: 1,
            class: "date icon",
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.togglePicker && _ctx.togglePicker(...args)))
          }, _cache[14] || (_cache[14] = [
            _createElementVNode("use", { "xlink:href": _hoisted_2 }, null, -1)
          ])))
        : _createCommentVNode("", true),
      (_ctx.type != 'file')
        ? _withDirectives((_openBlock(), _createElementBlock("input", {
            key: 2,
            ref: "input",
            type: _ctx.isShow ? 'text' : _ctx.inputType,
            value: _ctx.inputValue,
            placeholder: _ctx.type == 'datepicker' ? _ctx.datepickerHolder : _ctx.placeholder,
            onFocus: _cache[2] || (_cache[2] = ($event: any) => (_ctx.isFocus = !_ctx.isFocus)),
            onBlur: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.blurEvent && _ctx.blurEvent(...args))),
            tabindex: "0",
            onInput: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.inputEvent && _ctx.inputEvent(...args))),
            onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.inputClick && _ctx.inputClick(...args))),
            autocomplete: "do-not-autofill",
            max: "12",
            disabled: _ctx.disabled,
            readonly: _ctx.readonly
          }, null, 40, _hoisted_10)), [
            [_directive_maska, void 0, _ctx.maskOptions]
          ])
        : _withDirectives((_openBlock(), _createElementBlock("input", {
            key: 3,
            ref: "input",
            type: "file",
            value: _ctx.inputValue,
            tabindex: "0",
            disabled: _ctx.disabled,
            readonly: _ctx.readonly,
            accept: "application/pdf, image/jpeg, image/png",
            onChange: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.handleFileChange && _ctx.handleFileChange(...args)))
          }, null, 40, _hoisted_11)), [
            [_vShow, false]
          ]),
      (_ctx.type == 'password')
        ? (_openBlock(), _createElementBlock("svg", {
            key: 4,
            class: "hide-show",
            onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.togglePassword && _ctx.togglePassword(...args)))
          }, [
            _createElementVNode("use", {
              "xlink:href": require('@/assets/images/sprites/common-sprite.svg') + (_ctx.isShow ? '#hide-password' : '#show-password')
            }, null, 8, _hoisted_12)
          ]))
        : _createCommentVNode("", true),
      (_ctx.datalist && _ctx.datalist.length == 0)
        ? (_openBlock(), _createElementBlock("svg", _hoisted_13, _cache[15] || (_cache[15] = [
            _createElementVNode("use", { "xlink:href": _hoisted_3 }, null, -1)
          ])))
        : _createCommentVNode("", true),
      (_ctx.icon)
        ? (_openBlock(), _createElementBlock("svg", {
            key: 6,
            class: "icon",
            onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.$emit('iconClick')))
          }, [
            _createElementVNode("use", {
              "xlink:href": require('@/assets/images/sprites/common-sprite.svg') + '#' + _ctx.icon
            }, null, 8, _hoisted_14)
          ]))
        : _createCommentVNode("", true),
      (_ctx.type == 'file')
        ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
            _createElementVNode("span", {
              class: _normalizeClass(["file", { selected: _ctx.selectedFileName }])
            }, _toDisplayString(_ctx.selectedFileName != null ? _ctx.selectedFileName : _ctx.placeholder), 3),
            _createElementVNode("button", {
              class: "secondary browse",
              onClick: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_ctx.browseFile && _ctx.browseFile(...args)))
            }, _cache[16] || (_cache[16] = [
              _createElementVNode("span", null, "Browse", -1)
            ]))
          ]))
        : _createCommentVNode("", true),
      (_ctx.unit)
        ? (_openBlock(), _createElementBlock("span", _hoisted_16, _toDisplayString(_ctx.unit), 1))
        : _createCommentVNode("", true),
      (! _ctx.disabled && _ctx.inputValue != '' && _ctx.type == 'datepicker')
        ? (_openBlock(), _createElementBlock("svg", {
            key: 9,
            class: "cross icon",
            onClick: _cache[10] || (_cache[10] = 
//@ts-ignore
(...args) => (_ctx.clear && _ctx.clear(...args)))
          }, _cache[17] || (_cache[17] = [
            _createElementVNode("use", { "xlink:href": _hoisted_4 }, null, -1)
          ])))
        : _createCommentVNode("", true)
    ], 2),
    (_ctx.datalist)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(["list", {active: _ctx.datalist.length && _ctx.showList }])
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.datalist, (item, index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: _normalizeClass(["item", item.class]),
              key: index,
              onClick: ($event: any) => (_ctx.itemClick(item))
            }, [
              _createElementVNode("span", _hoisted_18, _toDisplayString(item.name), 1),
              (item?.info)
                ? (_openBlock(), _createElementBlock("span", _hoisted_19, _toDisplayString(item.info), 1))
                : _createCommentVNode("", true)
            ], 10, _hoisted_17))
          }), 128))
        ], 2))
      : _createCommentVNode("", true),
    (_ctx.type == 'datepicker')
      ? (_openBlock(), _createBlock(_component_datepicker_component, {
          key: 2,
          date: _ctx.inputValue,
          onSelectDate: _ctx.selectDate,
          active: _ctx.showPicker
        }, null, 8, ["date", "onSelectDate", "active"]))
      : _createCommentVNode("", true),
    (_ctx.description)
      ? (_openBlock(), _createElementBlock("span", _hoisted_20, _toDisplayString(_ctx.description), 1))
      : _createCommentVNode("", true),
    (_ctx.errors && _ctx.errors.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.errors, (error) => {
            return (_openBlock(), _createElementBlock(_Fragment, {
              key: error.$uid
            }, [
              (error.$message != '')
                ? (_openBlock(), _createElementBlock("span", _hoisted_22, _toDisplayString(error.$message), 1))
                : _createCommentVNode("", true)
            ], 64))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ], 34)), [
    [_directive_click_away, _ctx.hideFeature]
  ])
}