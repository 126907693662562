<template>
  <div class="container" :class="{ required: required }">
    <div class="label" v-if="label">
      <span class="info" v-if="required" tooltip-right="Required information">
        <svg class="required"><use xlink:href="@/assets/images/sprites/common-sprite.svg#info-icon"></use></svg>
      </span>
      <label>{{label}}</label>
    </div>
    <section v-click-away="close" @keyup.esc="close" @keydown="keyHelper" :class="{active: isActive, error: errors && errors.length, disabled: disabled, searchable: searchable}">
      <div class="selective" :class="{focus: isFocus, selected: selectedItem}" tabindex="0" @click="open">
        <svg v-if="selectedItem && selectedItem.icon" class="icon"><use :xlink:href="iconset + selectedItem.icon"></use></svg>
        <span class="placeholder">{{selectedItem ? (selectedItem.code ? selectedItem.code : selectedItem.name) : placeholder}}</span>
        <svg v-if="selectedItem && !disabled && removable" class="cross" @click.stop="removeItem"><use xlink:href="@/assets/images/sprites/common-sprite.svg#cross-icon"></use></svg>
        <svg v-else class="arrow"><use xlink:href="@/assets/images/sprites/common-sprite.svg#arrow-icon"></use></svg>
      </div>
      <div class="list">
        <div v-if="searchable" class="searchbar">
          <input-component placeholder="Search in list" v-model:value="searchText" />
        </div>
        
        <div class="items">
          <div class="item" ref="listItem" v-for="(item, index) in items" :key="index" :class="[item.class, { selected: selectedItem && selectedItem.key == item.key, hide: isHide(item.name, item.code), disabled: item.disabled }]" @click="selectItem(item)">
            <svg v-if="item.icon" class="icon"><use :xlink:href="iconset + item.icon"></use></svg>
            <span class="text">{{ item.name }}</span>
            <span v-if="item.code" class="code">({{ item.code }})</span>
          </div>

          <div v-for="(group, name) in grouplist" :key="name">
            <h2 class="item title" @click="toggleDropdown(group)" :class="{ 'hoverable': group.show }">
              <span class="grup-name">{{ name }}</span>
              <svg v-if="group.show" class="down-arrow" :class="{ rotate: group.state }"><use xlink:href="@/assets/images/sprites/common-sprite.svg#down-arrow-icon"></use></svg>
            </h2>
            
            <div class="sub-items" v-show="group.state">
              <div class="item" ref="listItem" v-for="(item, index) in group.items" :key="index" :class="[item.class, { selected: selectedItem && selectedItem.key == item.key, hide: isHide(item.name, item.code), disabled: item.disabled }]" @click="selectItem(item)">
                <span class="text">{{ item.name }}</span>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </section>
    <span class="description" v-if="description">{{description}}</span>
    <div class="input-errors" v-if="errors && errors.length">
      <template v-for="error of errors" :key="error.$uid">
        <span v-if="error.$message != ''">{{ error.$message }}</span>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import Input from "./Input.vue";

@Options({
  components: {
    'input-component': Input
  },
  props: {
    items: { type: Array },
    grouplist:{ type: Object },
    label: { type: String },
    value: { type: [Number, String, Object] },
    placeholder: { type: String},
    description: { type: String },
    disabled: { type: Boolean, default: false },
    errors: { types: Array },
    iconset: { type: String },
    searchable: { type: Boolean },
    removable: { type: Boolean, default: true },
    required: { type: Boolean, default: false },
  },
  emits:['update:value', 'selectChange'],
  watch: {
    selectedItem() {
      //this.$emit('update:value', this.selectedItem ? this.selectedItem.key ?? this.selectedItem.name : '');
      //this.$emit('selectChange', this.selectedItem ?? '');
    },
    items: {
      handler() {
        this.selectedItem = this.items.find((item: { key: string; }) => item.key == this.value) ?? null;
      },
      deep: true
    },
    grouplist: {
      handler() {
        let find;
        Object.entries(this.grouplist).forEach(
          ([key, value]) => {
            const obj: any = value as any;
            if (! find) {
              find = obj.items.find((item: any) => item.key == this.value);
              //obj.state = true;
            }
          }
        );
        this.selectedItem = find ?? null;
      },
      deep: true
    },
    value: {
      handler() {
        if (this.grouplist) {
          let find;
          Object.entries(this.grouplist).forEach(
            ([key, value]) => {
              const obj: any = value as any;
              if (! find) {
                find = obj.items.find((item: any) => item.key == this.value);
                //obj.state = true;
              }
            }
          );
          this.selectedItem = find ?? null;
        }
        if (this.items) {
          this.selectedItem = this.items.find((item: { key: string; }) => item.key == this.value) ?? null;
        }
      },
      immediate: true,
      deep: true
    }
  },
  mounted() {
    const selectedEl = this.$el.querySelector('.list .item.selected');
    if (selectedEl) selectedEl.scrollIntoView({block: "nearest"});
  },
  methods: {
    open() {
      if (this.disabled) return;
      this.isActive = !this.isActive;
      if (this.items && !this.items.length) return;
      this.$refs.listItem.map((item) => item.classList.remove('hover'));
      const topItem = this.$refs.listItem.find((item) => item.classList.contains('selected')) ?? this.$refs.listItem[0];
      topItem.scrollIntoView({block: "nearest"});
    },
    selectItem(item: item) {
      if (this.disabled) return;
      if (item.key == '-1') return;
      if (item.disabled) return;
      this.selectedItem = item;
      this.close();
      this.$emit('update:value', this.selectedItem.key);
      this.$emit('selectChange', this.selectedItem);
    },
    removeItem() {
      if (this.disabled) return;
      this.selectedItem = null;
      this.$emit('update:value', 0);
      this.$emit('selectChange', { key: 0, name: '' });
    },
    close() {
      this.isActive = false;
      this.searchText = '';
    },
    keyHelper(event: KeyboardEvent) {
      if (this.items && ! this.items.length) return;
      const lastIndex = this.$refs.listItem.length - 1;
      let currentIndex = this.$refs.listItem.findIndex((item) => item.classList.contains('hover'));
      if (currentIndex == -1 && this.selectedItem) currentIndex = this.$refs.listItem.findIndex((item) => item.classList.contains('selected'));
      if (event.key == 'ArrowDown' || event.key == 'ArrowUp') {
        if (! this.isActive) return this.open();
        this.$refs.listItem.map((item) => item.classList.remove('hover'));
        let newIndex = currentIndex;
        if (event.key == 'ArrowDown') {
          newIndex += 1;
          if (newIndex > lastIndex) return this.$refs.listItem[lastIndex].classList.add('hover');
        }
        if (event.key == 'ArrowUp') {
          newIndex -= 1;
          if (newIndex < 0) return this.$refs.listItem[0].classList.add('hover');
        }
        this.$refs.listItem[newIndex].classList.add('hover');
        this.$refs.listItem[newIndex].scrollIntoView({block: "nearest"});
      }
      if (event.key == 'Enter' && currentIndex > -1) {
        this.$refs.listItem[currentIndex].click();
      }
      if (event.key === 'Tab') {
        this.close();
      }
    },
    isHide(name: string, code: string): boolean {
      if (this.searchText == '') return false;
      if (this.grouplist) {
        for(const group in this.grouplist) {
          this.grouplist[group].state = true;
        }
      }
      if (code) {
        return ! (name.toLowerCase().includes(this.searchText.toLowerCase()) || code.includes(this.searchText)); 
      }
      return ! name.toLowerCase().includes(this.searchText.toLowerCase());
    },
    toggleDropdown(group) {
      //if (group.show) {
        group.state = !group.state;
      //}
    }
  },
})

export default class Select extends Vue {
  open!: any;
  items!: item[];
  grouplist!: groups[];
  value!: string;
  initialValue!: string;
  label!: string;
  placeholder!: string;
  description!: string;
  disabled!: boolean;
  errors!: any;
  isFocus!: boolean;
  isActive!: boolean;
  selectItem!: any;
  selectedItem!: item;
  iconset!: any;
  close!: any;
  keyHelper!: any;
  searchable!: boolean;
  searchText!: string;
  isHide!: any;
  toggleDropdown!: any;
  removeItem!: any;
  removable!: boolean;

  data() {
    return {
      isFocus: false,
      isActive: false,
      searchText: '',
      selectedItem: this.items ? this.items.find((item: { key: string; }) => item.key == String(this.value)) ?? null : null,
    }
  }
}

export interface groups { show: boolean, state: boolean, items: item[] }
export interface item { class: string, icon: string, key: string, name: string, code: string, disabled: boolean }
</script>

<style scoped>
.container {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.container .label {
  display: flex;
  align-items: center;
  gap: 2px;
  font-size: 12px;
  line-height: 16px;
  padding: 0 2px;
}
.container .info {
  height: 16px;
  width: 16px;
}
.container .info svg {
  width: 100%;
  height: 100%;
  stroke-width: 1.5px;
  stroke: var(--neutral800);
}
.container .info svg.required {
  animation: flash ease infinite 2s;
}
.container.required .info svg.required {
  stroke: var(--danger600);
}
.container label {
  color: var(--neutral800);
  font-weight: 500;
}
.container section {
  position: relative;
}
.container section .selective {
  display: flex;
  align-items: center;
  background-color: var(--white);
  border: 1px solid var(--neutral200);
  padding: 10px 10px 10px 15px;
  gap: 4px;
  border-radius: 4px;
  overflow: hidden;
  cursor: default;
  outline: none;
  white-space: nowrap;
  height: 42px;
}
.container.no-border section .selective {
  border: none !important;
}
.container section:not(.disabled) .selective:hover,
.container section:not(.disabled) .selective.focus,
.container section:not(.disabled) .selective:focus-visible {
  border-color: var(--primary600);
}
.container section.disabled .selective {
  background-color: var(--neutral150);
}
.container section.error .selective {
  border-color: var(--danger600);
}
.container section.error .selective:hover,
.container section.focus.error .selective,
.container section.focus.error .selective:hover {
  border-color: var(--danger600);
}
.container .selective .icon {
  width: 18px;
  height: 18px;
  min-width: 18px;
  min-height: 18px;
  stroke: var(--neutral800);
}
.container .selective .arrow {
  width: 16px;
  height: 16px;
  transition: ease-in-out all .3s;
  stroke: var(--neutral600);
}
.container section.active .selective .arrow {
  transform: rotate(180deg);
}
.container .selective .cross {
  width: 16px;
  height: 16px;
  transition: ease-in-out all .3s;
  stroke: var(--neutral600);
  opacity: .75;
  cursor: pointer;
}
.container .selective .cross:hover {
  opacity: 1;
}
.container .selective .placeholder {
  flex: 1;
  font-family: 'Poppins', sans-serif;
  color: var(--neutral400);
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  border: 0;
}
.container section .selective:hover .placeholder,
.container section .selective.focus .placeholder,
.container section .selective:focus-visible .placeholder {
  border-color: var(--neutral800);
}
.container section .selective.selected .placeholder {
  color: var(--neutral800);
}
.container section .list {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 100%;
  width: 100%;
  min-width: max-content;
  max-height: 200px;
  padding: 4px;
  background-color: var(--white);
  border: .5px solid var(--neutral150);
  box-shadow: 0px 1px 4px rgba(33, 33, 52, .1);
  border-radius: 4px;
  visibility: hidden;
  opacity: 0;
  z-index: -1;
  transition: ease-in-out all .1s;
}
.container section .items {
  overflow-y: auto;
}
.container section.active .list {
  top: calc(100% + 2px);
  visibility: visible;
  opacity: 1;
  z-index: 2;
}
.container section.searchable .list {
  padding-top: 50px;
}
.container section.searchable .searchbar {
  position: absolute;
  top: 4px;
  left: 4px;
  width: calc(100% - 8px);
}
.container section .list .item {
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  padding: 8px 10px;
  border-radius: 4px;
  gap: 8px;
  cursor: default;
}
.container section .list .item.selected {
  background-color: var(--primary100);
  font-weight: 500;
}
.container section .list .item:hover,
.container section .list .item.hover {
  background-color: var(--neutral100);
}
.container section .list .item.hide {
  display: none;
}
.container section .list .item.disabled {
  opacity: .25;
}
.container section .list .item .icon {
  width: 18px;
  height: 18px;
  min-width: 18px;
  min-height: 18px;
  stroke: var(--neutral800);
}
.container section .list .item .text {
  white-space: nowrap;
}
.container section .list .item .code {
  margin-left: auto;
  font-size: 12px;
}
.container section .list .item.title {
  color: var(--neutral400);
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  background-color: inherit;
  text-decoration: underline;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.container section .list .item.title.hoverable:hover{
  color: var(--buttonPrimary600);
  cursor: pointer;
}
.container .description {
  color: var(--neutral600);
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  padding: 0 2px;
}
.container .input-errors {
  display: flex;
  flex-direction: column;
  min-height: 16px;
  gap: 2px;
}
.container .input-errors span {
  color: var(--danger600);
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  padding: 0 2px;
}
.down-arrow {
  width: 12px;
  height: 12px;
  transition: 300ms all;
  stroke: var(--neutral800);
}
.rotate {
  transform: rotate(180deg);
}
.list .sub-items .item{
  padding-left: 20px !important;
}
@media screen and (max-width: 600px) {
  .container section .list {
    min-width: initial;
  }
}
</style>