import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/sprites/common-sprite.svg'


const _hoisted_1 = _imports_0 + '#plus'
const _hoisted_2 = { class: "faq" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_2, _cache[0] || (_cache[0] = [
    _createElementVNode("h3", null, "FAQ", -1),
    _createElementVNode("div", { class: "wrapper" }, [
      _createElementVNode("div", { class: "item active" }, [
        _createElementVNode("input", {
          type: "checkbox",
          id: "item-1",
          checked: ""
        }),
        _createElementVNode("label", { for: "item-1" }, [
          _createElementVNode("span", null, "What is Logictics, and how can it help my business?"),
          _createElementVNode("svg", null, [
            _createElementVNode("use", { "xlink:href": _hoisted_1 })
          ])
        ]),
        _createElementVNode("span", null, "Logictics SaaS is a comprehensive logistics management platform designed to optimize your supply chain operations. From real-time tracking to route planning and document management, it simplifies processes, reduces costs, and improves efficiency.")
      ]),
      _createElementVNode("div", { class: "item" }, [
        _createElementVNode("input", {
          type: "checkbox",
          id: "item-2"
        }),
        _createElementVNode("label", { for: "item-2" }, [
          _createElementVNode("span", null, "Can Logictics integrate with my existing systems?"),
          _createElementVNode("svg", null, [
            _createElementVNode("use", { "xlink:href": _hoisted_1 })
          ])
        ]),
        _createElementVNode("span", null, "Logictics SaaS is a comprehensive logistics management platform designed to optimize your supply chain operations. From real-time tracking to route planning and document management, it simplifies processes, reduces costs, and improves efficiency.")
      ]),
      _createElementVNode("div", { class: "item" }, [
        _createElementVNode("input", {
          type: "checkbox",
          id: "item-3"
        }),
        _createElementVNode("label", { for: "item-3" }, [
          _createElementVNode("span", null, "Is my data secure on Logictics?"),
          _createElementVNode("svg", null, [
            _createElementVNode("use", { "xlink:href": _hoisted_1 })
          ])
        ]),
        _createElementVNode("span", null, "Logictics SaaS is a comprehensive logistics management platform designed to optimize your supply chain operations. From real-time tracking to route planning and document management, it simplifies processes, reduces costs, and improves efficiency.")
      ]),
      _createElementVNode("div", { class: "item" }, [
        _createElementVNode("input", {
          type: "checkbox",
          id: "item-4"
        }),
        _createElementVNode("label", { for: "item-4" }, [
          _createElementVNode("span", null, "Does Logictics support real-time shipment tracking?"),
          _createElementVNode("svg", null, [
            _createElementVNode("use", { "xlink:href": _hoisted_1 })
          ])
        ]),
        _createElementVNode("span", null, "Logictics SaaS is a comprehensive logistics management platform designed to optimize your supply chain operations. From real-time tracking to route planning and document management, it simplifies processes, reduces costs, and improves efficiency.")
      ]),
      _createElementVNode("div", { class: "item" }, [
        _createElementVNode("input", {
          type: "checkbox",
          id: "item-5"
        }),
        _createElementVNode("label", { for: "item-5" }, [
          _createElementVNode("span", null, "What is Logictics , and how can it help my business?"),
          _createElementVNode("svg", null, [
            _createElementVNode("use", { "xlink:href": _hoisted_1 })
          ])
        ]),
        _createElementVNode("span", null, "Logictics SaaS is a comprehensive logistics management platform designed to optimize your supply chain operations. From real-time tracking to route planning and document management, it simplifies processes, reduces costs, and improves efficiency.")
      ]),
      _createElementVNode("div", { class: "item" }, [
        _createElementVNode("input", {
          type: "checkbox",
          id: "item-6"
        }),
        _createElementVNode("label", { for: "item-6" }, [
          _createElementVNode("span", null, "Can I customize the platform for my business needs?"),
          _createElementVNode("svg", null, [
            _createElementVNode("use", { "xlink:href": _hoisted_1 })
          ])
        ]),
        _createElementVNode("span", null, "Logictics SaaS is a comprehensive logistics management platform designed to optimize your supply chain operations. From real-time tracking to route planning and document management, it simplifies processes, reduces costs, and improves efficiency.")
      ]),
      _createElementVNode("div", { class: "item" }, [
        _createElementVNode("input", {
          type: "checkbox",
          id: "item-7"
        }),
        _createElementVNode("label", { for: "item-7" }, [
          _createElementVNode("span", null, "How can I get started with Logictics?"),
          _createElementVNode("svg", null, [
            _createElementVNode("use", { "xlink:href": _hoisted_1 })
          ])
        ]),
        _createElementVNode("span", null, "Logictics SaaS is a comprehensive logistics management platform designed to optimize your supply chain operations. From real-time tracking to route planning and document management, it simplifies processes, reduces costs, and improves efficiency.")
      ]),
      _createElementVNode("div", { class: "item" }, [
        _createElementVNode("input", {
          type: "checkbox",
          id: "item-8"
        }),
        _createElementVNode("label", { for: "item-8" }, [
          _createElementVNode("span", null, "Is customer support available if I have issues?"),
          _createElementVNode("svg", null, [
            _createElementVNode("use", { "xlink:href": _hoisted_1 })
          ])
        ]),
        _createElementVNode("span", null, "Logictics SaaS is a comprehensive logistics management platform designed to optimize your supply chain operations. From real-time tracking to route planning and document management, it simplifies processes, reduces costs, and improves efficiency.")
      ])
    ], -1)
  ])))
}