import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "testimonials" }
const _hoisted_2 = { class: "wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_swiper_slide = _resolveComponent("swiper-slide")!
  const _component_swiper = _resolveComponent("swiper")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[5] || (_cache[5] = _createElementVNode("h4", null, "Testimonials", -1)),
    _cache[6] || (_cache[6] = _createElementVNode("h2", null, "Trusted by Industry Leaders", -1)),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_swiper, {
        class: "slider",
        modules: _ctx.modules,
        pagination: _ctx.pagination,
        loop: true,
        "space-between": 25,
        breakpoints: _ctx.breakpoints
      }, {
        default: _withCtx(() => [
          _createVNode(_component_swiper_slide, null, {
            default: _withCtx(() => _cache[0] || (_cache[0] = [
              _createElementVNode("div", { class: "item" }, [
                _createElementVNode("blockquote", null, "“With Logictics, we reduced our shipment delays by 25%. The real-time tracking feature has been a game-changer for our operations!”"),
                _createElementVNode("div", { class: "person" }, [
                  _createElementVNode("div", { class: "photo" }),
                  _createElementVNode("div", { class: "info" }, [
                    _createElementVNode("span", { class: "name" }, "Mark T."),
                    _createElementVNode("span", { class: "title" }, "Logistics Manager at Speedline Freight")
                  ])
                ])
              ], -1)
            ])),
            _: 1
          }),
          _createVNode(_component_swiper_slide, null, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [
              _createElementVNode("div", { class: "item" }, [
                _createElementVNode("blockquote", null, "\"Managing our supply chain used to be chaotic. Thanks to Logictics, we now have full visibility and control over our shipments.\""),
                _createElementVNode("div", { class: "person" }, [
                  _createElementVNode("div", { class: "photo" }),
                  _createElementVNode("div", { class: "info" }, [
                    _createElementVNode("span", { class: "name" }, "Anna K."),
                    _createElementVNode("span", { class: "title" }, "CEO of TrendyDepot")
                  ])
                ])
              ], -1)
            ])),
            _: 1
          }),
          _createVNode(_component_swiper_slide, null, {
            default: _withCtx(() => _cache[2] || (_cache[2] = [
              _createElementVNode("div", { class: "item" }, [
                _createElementVNode("blockquote", null, "“The seamless integration with our existing ERP system made the transition to Logictics effortless. Highly recommended!”"),
                _createElementVNode("div", { class: "person" }, [
                  _createElementVNode("div", { class: "photo" }),
                  _createElementVNode("div", { class: "info" }, [
                    _createElementVNode("span", { class: "name" }, "David L."),
                    _createElementVNode("span", { class: "title" }, "Owner of Green Fields Logistics")
                  ])
                ])
              ], -1)
            ])),
            _: 1
          }),
          _createVNode(_component_swiper_slide, null, {
            default: _withCtx(() => _cache[3] || (_cache[3] = [
              _createElementVNode("div", { class: "item" }, [
                _createElementVNode("blockquote", null, "\"Logictics' route optimization feature helped us cut transportation costs by 30%. It's an invaluable tool for our team.\""),
                _createElementVNode("div", { class: "person" }, [
                  _createElementVNode("div", { class: "photo" }),
                  _createElementVNode("div", { class: "info" }, [
                    _createElementVNode("span", { class: "name" }, "Sophia R."),
                    _createElementVNode("span", { class: "title" }, "Operations Director at GlobalShip")
                  ])
                ])
              ], -1)
            ])),
            _: 1
          }),
          _cache[4] || (_cache[4] = _createElementVNode("div", { class: "pagination" }, null, -1))
        ]),
        _: 1
      }, 8, ["modules", "pagination", "breakpoints"])
    ])
  ]))
}