import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 1,
  class: "description"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_select_component = _resolveComponent("select-component")!
  const _component_input_component = _resolveComponent("input-component")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", _hoisted_2, _toDisplayString(_ctx.label), 1))
      : _createCommentVNode("", true),
    _createElementVNode("section", {
      class: _normalizeClass({disabled: _ctx.disabled})
    }, [
      _createVNode(_component_select_component, {
        class: "prefix",
        iconset: require('@/assets/images/sprites/flags.svg'),
        items: _ctx.codes,
        value: _ctx._phone.code,
        "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx._phone.code) = $event)),
        onSelectChange: _cache[1] || (_cache[1] = ($event: any) => (console.log($event))),
        disabled: _ctx.disabled,
        searchable: true,
        removable: false
      }, null, 8, ["iconset", "items", "value", "disabled"]),
      _createVNode(_component_input_component, {
        class: "number",
        placeholder: _ctx.placeholder,
        value: _ctx._phone.number,
        "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx._phone.number) = $event)),
        errors: _ctx.errors && _ctx.errors.number.$errors,
        disabled: _ctx.disabled
      }, null, 8, ["placeholder", "value", "errors", "disabled"])
    ], 2),
    (_ctx.description)
      ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.description), 1))
      : _createCommentVNode("", true)
  ]))
}