import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "contents" }
const _hoisted_2 = { class: "wrapper" }
const _hoisted_3 = { class: "item" }
const _hoisted_4 = { class: "text" }
const _hoisted_5 = { class: "item" }
const _hoisted_6 = { class: "text" }
const _hoisted_7 = { class: "item" }
const _hoisted_8 = { class: "text" }
const _hoisted_9 = { class: "item" }
const _hoisted_10 = { class: "text" }
const _hoisted_11 = { class: "item" }
const _hoisted_12 = { class: "text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[20] || (_cache[20] = _createElementVNode("h3", null, "Discover the Power of Smart Logistics", -1)),
      _createElementVNode("div", _hoisted_3, [
        _cache[7] || (_cache[7] = _createElementVNode("div", { class: "image dashboard" }, null, -1)),
        _createElementVNode("div", _hoisted_4, [
          _cache[5] || (_cache[5] = _createElementVNode("h2", null, "Manage Shipments Effortlessly", -1)),
          _cache[6] || (_cache[6] = _createElementVNode("h4", null, "Access detailed shipment data, filter by status, and update records in just a few clicks.", -1)),
          _createElementVNode("button", {
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.shipments && _ctx.shipments(...args)))
          }, "Add New Shipment")
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _cache[10] || (_cache[10] = _createElementVNode("div", { class: "image quote" }, null, -1)),
        _createElementVNode("div", _hoisted_6, [
          _cache[8] || (_cache[8] = _createElementVNode("h2", null, "Streamline Your Quote Management", -1)),
          _cache[9] || (_cache[9] = _createElementVNode("h4", null, "Create and track shipping quotes effortlessly, with real-time updates on status.", -1)),
          _createElementVNode("button", {
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.quotes && _ctx.quotes(...args)))
          }, "Request a Quote")
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _cache[13] || (_cache[13] = _createElementVNode("div", { class: "image document" }, null, -1)),
        _createElementVNode("div", _hoisted_8, [
          _cache[11] || (_cache[11] = _createElementVNode("h2", null, "Document Management", -1)),
          _cache[12] || (_cache[12] = _createElementVNode("h4", null, "Organize and access your MSDS, CO, and other critical documents with ease.", -1)),
          _createElementVNode("button", {
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.documents && _ctx.documents(...args)))
          }, "Upload New Document")
        ])
      ]),
      _createElementVNode("div", _hoisted_9, [
        _cache[16] || (_cache[16] = _createElementVNode("div", { class: "image shipment" }, null, -1)),
        _createElementVNode("div", _hoisted_10, [
          _cache[14] || (_cache[14] = _createElementVNode("h2", null, "Simplify Shipment Updates", -1)),
          _cache[15] || (_cache[15] = _createElementVNode("h4", null, "Edit delivery details, assign agents, and streamline booking with a few clicks.", -1)),
          _createElementVNode("button", {
            onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.shipments && _ctx.shipments(...args)))
          }, "Save Changes")
        ])
      ]),
      _createElementVNode("div", _hoisted_11, [
        _cache[19] || (_cache[19] = _createElementVNode("div", { class: "image request" }, null, -1)),
        _createElementVNode("div", _hoisted_12, [
          _cache[17] || (_cache[17] = _createElementVNode("h2", null, "Request a Quote in Minutes", -1)),
          _cache[18] || (_cache[18] = _createElementVNode("h4", null, "Specify your shipping requirements and get tailored quotes instantly.", -1)),
          _createElementVNode("button", {
            onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.quotes && _ctx.quotes(...args)))
          }, "Send Request")
        ])
      ])
    ])
  ]))
}