import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/sprites/common-sprite.svg'


const _hoisted_1 = _imports_0 + '#arrow-right'
const _hoisted_2 = { class: "help" }
const _hoisted_3 = { class: "wrapper" }
const _hoisted_4 = { class: "form" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_input_component = _resolveComponent("input-component")!
  const _component_phone_component = _resolveComponent("phone-component")!
  const _component_select_component = _resolveComponent("select-component")!

  return (_openBlock(), _createElementBlock("div", _hoisted_2, [
    _createElementVNode("div", _hoisted_3, [
      _cache[2] || (_cache[2] = _createElementVNode("section", null, [
        _createElementVNode("h1", null, "Get started with us"),
        _createElementVNode("h3", null, "Don't hesitate to get started with us! Our team is ready to assist you 24/7. Simply fill out the form on the right, and we'll help you streamline your logistics operations today.")
      ], -1)),
      _createElementVNode("section", null, [
        _createElementVNode("div", _hoisted_4, [
          _cache[0] || (_cache[0] = _createElementVNode("label", null, "Ask a question", -1)),
          _createVNode(_component_input_component, {
            class: "input",
            placeholder: "Full Name"
          }),
          _createVNode(_component_input_component, {
            class: "input",
            placeholder: "Company Email"
          }),
          _createVNode(_component_input_component, {
            class: "input",
            placeholder: "Company Name"
          }),
          _createVNode(_component_phone_component, {
            class: "phone",
            placeholder: "00000000000"
          }),
          _createVNode(_component_select_component, {
            class: "select",
            placeholder: "Country",
            items: [{ key: 1, name: 'test' }]
          }),
          _cache[1] || (_cache[1] = _createElementVNode("button", { class: "primary large" }, [
            _createElementVNode("span", null, "Talk with our team"),
            _createElementVNode("svg", null, [
              _createElementVNode("use", { "xlink:href": _hoisted_1 })
            ])
          ], -1))
        ])
      ])
    ])
  ]))
}