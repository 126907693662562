import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/sprites/common-sprite.svg'


const _hoisted_1 = _imports_0 + '#app-logo-text-2'
const _hoisted_2 = _imports_0 + '#app-logo-image'
const _hoisted_3 = _imports_0 + '#social-icon-facebook'
const _hoisted_4 = _imports_0 + '#social-icon-instagram'
const _hoisted_5 = _imports_0 + '#social-icon-x'
const _hoisted_6 = _imports_0 + '#social-icon-linkedin'
const _hoisted_7 = _imports_0 + '#social-icon-telegram'
const _hoisted_8 = { class: "footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_8, _cache[0] || (_cache[0] = [
    _createElementVNode("div", { class: "wrapper" }, [
      _createElementVNode("div", { class: "top" }, [
        _createElementVNode("section", null, [
          _createElementVNode("div", { class: "logo" }, [
            _createElementVNode("svg", { class: "text" }, [
              _createElementVNode("use", { "xlink:href": _hoisted_1 })
            ]),
            _createElementVNode("svg", { class: "image" }, [
              _createElementVNode("use", { "xlink:href": _hoisted_2 })
            ])
          ]),
          _createElementVNode("span", { class: "copyright" }, "2024 © Logictics. All rights reserved.")
        ]),
        _createElementVNode("section", null, [
          _createElementVNode("svg", { class: "social" }, [
            _createElementVNode("use", { "xlink:href": _hoisted_3 })
          ]),
          _createElementVNode("svg", { class: "social" }, [
            _createElementVNode("use", { "xlink:href": _hoisted_4 })
          ]),
          _createElementVNode("svg", { class: "social" }, [
            _createElementVNode("use", { "xlink:href": _hoisted_5 })
          ]),
          _createElementVNode("svg", { class: "social" }, [
            _createElementVNode("use", { "xlink:href": _hoisted_6 })
          ]),
          _createElementVNode("svg", { class: "social" }, [
            _createElementVNode("use", { "xlink:href": _hoisted_7 })
          ])
        ])
      ]),
      _createElementVNode("div", { class: "bottom" }, " Founded in 2023, Logictics is a trusted logistics platform designed to optimize and streamline supply chain operations globally. Logictics helps businesses of all sizes manage their logistics needs, from a single shipment to large-scale operations, with ease. With cutting-edge technology, real-time tracking, and 24/7 customer support, Logictics is the simplest way to manage shipments, optimize routes, and collaborate with partners worldwide. Whether you're shipping goods, managing fleets, or ensuring warehouse efficiency, Logictics is your all-in-one logistics solution. ")
    ], -1)
  ])))
}