import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/sprites/common-sprite.svg'


const _hoisted_1 = _imports_0 + '#app-logo-image'
const _hoisted_2 = _imports_0 + '#hamburger-icon'
const _hoisted_3 = _imports_0 + '#right-arrow-icon'
const _hoisted_4 = { class: "navigation" }
const _hoisted_5 = { class: "wrapper" }
const _hoisted_6 = { class: "app-route" }
const _hoisted_7 = ["href"]
const _hoisted_8 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_4, [
    _createElementVNode("div", _hoisted_5, [
      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "logo" }, [
        _createElementVNode("svg", null, [
          _createElementVNode("use", { "xlink:href": _hoisted_1 })
        ])
      ], -1)),
      _cache[2] || (_cache[2] = _createElementVNode("div", { class: "nav" }, [
        _createElementVNode("input", {
          type: "checkbox",
          id: "menu-toggle"
        }),
        _createElementVNode("label", {
          for: "menu-toggle",
          class: "holder"
        }, [
          _createElementVNode("svg", null, [
            _createElementVNode("use", { "xlink:href": _hoisted_2 })
          ])
        ]),
        _createElementVNode("div", { class: "items" }, [
          _createElementVNode("a", { href: "#" }, "Why Logictics"),
          _createElementVNode("a", { href: "#" }, "Products"),
          _createElementVNode("a", { href: "#" }, "Use Cases"),
          _createElementVNode("a", { href: "#" }, "Pricing"),
          _createElementVNode("a", { href: "#" }, "About Us")
        ])
      ], -1)),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("a", {
          href: `//app.${_ctx.baseDomain}/login`,
          target: "_blank"
        }, "Log in", 8, _hoisted_7),
        _createElementVNode("a", {
          href: `//app.${_ctx.baseDomain}/register`,
          target: "_blank",
          class: "started"
        }, _cache[0] || (_cache[0] = [
          _createElementVNode("span", null, "Get Started", -1),
          _createElementVNode("svg", null, [
            _createElementVNode("use", { "xlink:href": _hoisted_3 })
          ], -1)
        ]), 8, _hoisted_8)
      ])
    ])
  ]))
}