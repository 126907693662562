<template>
  
  <template v-if="isNonRoute">
    <div class="marketing">
      <navigation></navigation>
      <mainarea></mainarea>
      <contentarea></contentarea>
      <!-- <testimonials></testimonials>
      <faq></faq> -->
      <help></help>
      <foot></foot>
    </div>
  </template>
  <template v-else>
    <router-view></router-view>
  </template>

  <!-- <div class="container">
    <svg class="logo"><use xlink:href="@/assets/images/sprites/common-sprite.svg#app-logo-text"></use></svg>
    <div class="nav">
      <a :href="`//app.${baseDomain}/login`">Sign In</a>
      <router-link to="/terms-of-service">Terms Of Service</router-link>
      <router-link to="/privacy-policy">Privacy Policy</router-link>
    </div>
  </div> -->
  
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import Navigation from '../marketing/Navigation.vue';
import MainArea from '../marketing/MainArea.vue';
import ContentArea from '../marketing/ContentArea.vue';
import Testimonials from '../marketing/Testimonials.vue';
import Faq from '../marketing/Faq.vue';
import Help from '../marketing/Help.vue';
import Foot from '../marketing/Foot.vue';

@Options({
  components: {
    'navigation': Navigation,
    'mainarea': MainArea, 
    'contentarea': ContentArea,
    'testimonials': Testimonials,
    'faq': Faq,
    'help': Help,
    'foot': Foot
  },
  computed: {
    isNonRoute(): boolean { return this.$route.matched.length == 1 }
  }
})

export default class Landing extends Vue {
  isNonRoute!: boolean;
}
</script>

<style scoped>
  .marketing {
    background-color: var(--white);
    width: 100%;
    min-height: 100%;
  }
  :deep(.wrapper) {
    width: 1280px;
    max-width: calc(100% - 40px);
    margin: 0 auto;
  }
  /* .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  .container .logo {
    width: 300px;
    max-width: 50%;
  }
  .container .nav {
    display: flex;
    gap: 15px;
  }
  .container .nav a {
    position: relative;
  }
  .container .nav a + a:before {
    content: "";
    position: absolute;
    width: 1px;
    height: 70%;
    background-color: var(--primary600);
    left: -8px;
    top: 15%;
  } */
</style>