<template>
  <div class="container">
    <label v-if="label">{{label}}</label>
    <section :class="{disabled: disabled}">
      <select-component class="prefix" :iconset="require('@/assets/images/sprites/flags.svg')" :items="codes" v-model:value="_phone.code" @selectChange="console.log($event)" :disabled="disabled" :searchable="true" :removable="false" />
      <input-component class="number" :placeholder="placeholder" v-model:value="_phone.number" :errors="errors && errors.number.$errors" :disabled="disabled" />
    </section>
    <span class="description" v-if="description">{{description}}</span>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import Input from "./Input.vue";
import Select from "./Select.vue";
import { PhoneCodeModel } from "@/models/variables/phoneCodes";
import { VariableServices } from "@/services/Variables";

@Options({
  components: {
    'select-component': Select,
    'input-component': Input
  },
  watch: {
    _phone: {
      handler() {
        this.$emit('update:phone', this._phone);
      },
      deep: true
    }
  },
  props: {
    phone: {
      type: Object,
      default: null,
    },
    label: {type: String},
    placeholder: {type: String},
    description: {type: String},
    disabled: {
      type: Boolean,
      default: false
    },
    errors: {types: Object},
  },
  updated() {
    this._phone = this.phone;
  },
  async created() {
    await this.variableServices.get('phone_codes').then((response) => {
      const codes: PhoneCodeModel[] = response.data.map((item: PhoneCodeModel) => new PhoneCodeModel(item));
      codes.forEach((item: PhoneCodeModel) => {
        item.data.forEach(country => {
          this.codes.push({ icon: '#' + country.code, key: country.code, name: country.name, code: item.name });
        });
      });
    });
  },
})

export default class Phone extends Vue {
  phone!: phone;
  _phone!: phone;
  label!: string;
  placeholder!: string;
  description!: string;
  disabled!: boolean;
  errors!: any;
  codes!: [];
  variableServices!: VariableServices;

  data() {
    return {
      codes: [],
      _phone: this.phone ?? { code: 'US', prefix: '1', number: '' },
      variableServices: VariableServices.getInstance()
    }
  }
}

export interface phone { code: string, prefix: string, number: string }
</script>

<style scoped>
.container {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.container label {
  color: var(--neutral800);
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  padding: 0 2px;
}
.container section {
  display: flex;
  gap: 5px;
}
.container section .prefix {
  position: inherit;
  width: auto;
  min-width: 90px;
}
.container section .prefix :deep(section) {
  position: inherit;
}
.container section .number {
  flex: 1;
}
</style>