<template>
  <div class="main">
    <div class="wrapper">
      <div class="item">
        <h1>Stay on Top of Your Logistics Operations</h1>
        <h4>Track your shipments in real-time with our intuitive timeline view. Filter by status to focus on what's important.</h4>
        <button @click="shipments">Track Shipments</button>
      </div>
      <div class="item">
        <video class="port" autoplay loop muted playsinline>
          <source :src="require('@/assets/videos/main.webm')" type="video/webm">
          <source :src="require('@/assets/videos/main.mp4')" type="video/mp4">
        </video>
        <!-- <img class="port" src="../../assets/images/marketings/truck.svg" /> -->
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

@Options({
  computed: {
    baseDomain () {
      const host = window.location.host;
      const domain = host.includes('.') ? host.split('.').slice(-2).join('.') : host;
      return domain;
    }
  },
  methods: {
    shipments () {
      window.open(`//app.${this.baseDomain}/shipments`, '_blank');
    }
  }
})

export default class MainArea extends Vue {
  shipments!: any;
}
</script>

<style scoped>
  .main {
    position: relative;
    padding: 60px 0 0 0;
    /* border-bottom: 1px solid var(--neutral300); */
  }
  .main::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: var(--neutral300);
    bottom: 0;
    left: 0;
  }
  .wrapper {
    display: flex;
  }
  .item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    flex: 1;
    gap: 32px;
  }
  .item:first-child {
    padding-bottom: 40px;
  }
  .item:last-child {
    justify-content: flex-end;
  }
  .item .port {
    width: calc(100% + 80px);
    transition: all .25s;
  }
  h1 {
    color: var(--neutral1000);
    font-size: 74px;
    font-weight: 600;
    line-height: 84px;
    transition: all .25s;
  }
  h4 {
    color: var(--neutral1000);
    font-size: 20px;
    line-height: 24px;
    transition: all .25s;
  }
  button {
    font-size: 16px;
    line-height: 22px;
    padding: 14px 24px;
    background-color: var(--black);
    border-radius: 24px;
    color: var(--white);
    transition: all .25s;
  }
  @media screen and (max-width: 1280px) {
     h1 {
      font-size: 48px;
      line-height: 54px;
    }
    h4 {
      font-size: 16px;
      line-height: 18px;
    }
    .item .port {
      width: calc(100% + 40px);
    }
  }
  @media screen and (max-width: 1024px) {
    h1 {
      font-size: 42px;
      line-height: 52px;
    }
    h4 {
      font-size: 16px;
      line-height: 18px;
    }
    button {
      font-size: 14px;
      padding: 12px 20px;
    }
  }
  @media screen and (max-width: 800px) {
    .wrapper {
      flex-direction: column;
    }
    .item {
      width: 100%;
      align-items: center;
    }
    h1 {
      font-size: 32px;
      line-height: 42px;
    }
    h4 {
      font-size: 14px;
      line-height: 16px;
    }
    button {
      font-size: 12px;
      padding: 10px 18px;
    }
  }
</style>