<template>
  <div class="container" :class="{ required: required }" v-click-away="hideFeature" @keyup.esc="hideFeature" @keyup.enter="hideFeature">

    <div class="label" v-if="label || labelitems">
      <span class="info" v-if="required" tooltip-right="Required information">
        <svg class="required"><use xlink:href="@/assets/images/sprites/common-sprite.svg#info-icon"></use></svg>
      </span>
      <label v-if="label">{{ label }}</label>
      <div class="items" v-if="labelitems">
        <span v-for="(item, index) in labelitems" :key="index" :class="item.class" @click="emitter(item)">{{ item.text }}</span>
      </div>
    </div>

    <section :class="{ focus: isFocus, error: errors && errors.length, disabled: disabled, money: type == 'money' }">
      <component :is="currencyComponent" v-if="type == 'money'" class="currency no-border" @select-change="$emit('changeCurrency', $event)" :items="currencies" :value="currencyValue || user.currency" :disabled="disabled" />

      <svg v-if="type == 'datepicker'" class="date icon" @click="togglePicker" ><use xlink:href="@/assets/images/sprites/common-sprite.svg#calender"></use></svg>

      <input v-if="type != 'file'" ref="input" :type="isShow ? 'text' : inputType" :value="inputValue" :placeholder="type == 'datepicker' ? datepickerHolder : placeholder" @focus="isFocus = !isFocus" @blur="blurEvent" tabindex="0" @input="inputEvent" @click="inputClick" autocomplete="do-not-autofill" max="12" :disabled="disabled" :readonly="readonly" v-maska:[maskOptions] />
      
      <input v-else v-show="false" ref="input" type="file" :value="inputValue" tabindex="0" :disabled="disabled" :readonly="readonly" accept="application/pdf, image/jpeg, image/png" @change="handleFileChange" />

      <svg v-if="type == 'password'" class="hide-show" @click="togglePassword"><use :xlink:href="require('@/assets/images/sprites/common-sprite.svg') + (isShow ? '#hide-password' : '#show-password')"></use></svg>

      <svg v-if="datalist && datalist.length == 0" class="loading"><use xlink:href="@/assets/images/sprites/common-sprite.svg#loading-icon"></use></svg>

      <svg v-if="icon" class="icon" @click="$emit('iconClick')"><use :xlink:href="require('@/assets/images/sprites/common-sprite.svg') + '#' + icon"></use></svg>

      <div v-if="type == 'file'" class="file-container">
        <span class="file" :class="{ selected: selectedFileName }">{{ selectedFileName != null ? selectedFileName : placeholder }}</span>
        <button class="secondary browse" @click="browseFile">
          <!-- <svg><use xlink:href="@/assets/images/sprites/common-sprite.svg#upload-file-icon"></use></svg> -->
          <span>Browse</span>
        </button>
      </div>

      <span v-if="unit" class="unit">{{ unit }}</span>

      <svg v-if="! disabled && inputValue != '' && type == 'datepicker'" class="cross icon" @click="clear" ><use xlink:href="@/assets/images/sprites/common-sprite.svg#cross-icon"></use></svg>

    </section>

    <div v-if="datalist" class="list" :class="{active: datalist.length && showList }">
      <div class="item" v-for="(item, index) in datalist" :key="index" :class="item.class" @click="itemClick(item)">
        <span class="name">{{item.name}}</span>
        <span v-if="item?.info" class="info">{{item.info}}</span>
      </div>
    </div>
    
    <datepicker-component v-if="type == 'datepicker'" :date="inputValue" @select-date="selectDate" :active="showPicker" />
    
    <span class="input-description" v-if="description">{{ description }}</span>

    <div class="input-errors" v-if="errors && errors.length">
      <template v-for="error of errors" :key="error.$uid">
        <span v-if="error.$message != ''">{{ error.$message }}</span>
      </template>
    </div>

  </div>
  
</template>

<script lang="ts">
import { Options, Vue, VueConstructor } from "vue-class-component";
import DatePicker from "./DatePicker.vue";
import { vMaska } from "maska"
import { PropType, markRaw } from "vue";
import moment from 'moment';
import Modal from "../shared/Modal.vue";
import { VariableServices } from '@/services/Variables';
import { CurrencyModel } from "@/models/variables/currency";
import { User } from "@/models/commons/user";

@Options({
  directives: { maska: vMaska },
  components: {
    'datepicker-component': DatePicker,
    'modal-component': Modal,
    //'select-component': Select
  },
  computed: {
    user(): User { return new User(this.$store.state.user) },
  },
  props: {
    type: {
      type: String,
      default: 'text',
      validator: (value: string): boolean => ['text', 'number', 'email', 'password', 'datepicker', 'file', 'money'].indexOf(value)!=-1
    },
    value: { type: [Number, String], default: '' },
    currency: {type: String},
    label: {type: String},
    labelitems: {type: Array},
    placeholder: {type: String},
    description: {type: String},
    disabled: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
    errors: {types: Array},
    datalist: { type: Array as PropType<item[]> },
    icon: {type: String},
    delay: {type: Number},
    browseText: {type: String},
    unit: {type: String},
    required: { type: Boolean, default: false },
  },
  emits: ['update:value', 'inputChange', 'dataLoad', 'itemSelect', 'iconClick', 'inputBlur', 'changeCurrency'],
  watch: {
    value(newValue) {
      this.inputValue = newValue;
      if (this.type == 'datepicker') this.setDate();
    },
    currency(newValue) {
      this.currencyValue = newValue;
    },
  },
  async created() {
    if (this.type == 'datepicker') this.setDate();
    if (this.type == 'money') this.setMoney();
  },
  methods: {
    setDate() {
      this.maskOptions.mask = this.user.date_format.id
        .replace('YYYY', '####')
        .replace('YY', '##')
        .replace('MMM', '@@@')
        .replace('MM', '##')
        .replace('DD', '##');
      this.datepickerHolder = this.user.date_format.id.replace(/[a-zA-Z]/g, '-');
      const date = moment(this.value, 'YYYY-MM-DD', true);
      if (date.isValid()) this.inputValue = date.format(this.user.date_format.id);
    },
    async setMoney() {
      await import('./Select.vue').then(module => this.currencyComponent = markRaw(module.default) );
      await this.variableServices.get('currencies').then((response) => {
        const currencies: CurrencyModel[] = response.data.map((item: CurrencyModel) => new CurrencyModel(item));
        currencies.forEach((item: CurrencyModel) => this.currencies.push({ key: item.id, name: item.id }) );
      });
    },
    togglePassword(): void {
      this.isShow = !this.isShow;
    },
    inputEvent(event: Event): void {
      let input: HTMLInputElement = event.target as HTMLInputElement;
      this.inputValue = input.value;
      
      if (this.type == 'datepicker') {
        const date = moment(this.inputValue, this.user.date_format.id, true);
        if (date.isValid()) this.$emit('inputChange', date.format('YYYY-MM-DD'));
        else this.$emit('inputChange', '');
      }
      else {
        this.$emit('update:value', this.inputValue);
        this.$emit('inputChange', this.inputValue);
      }
      
      if (this.isFocus) {
        this.showList = true;
        if (this.delay && this.inputValue != '') {
          if (this.delayTimer) clearTimeout(this.delayTimer);
          this.delayTimer = setTimeout(() => this.$emit('dataLoad', this.inputValue), this.delay);
        }
        else {
          this.$emit('dataLoad', this.inputValue);
        }
      }
    },
    blurEvent(event: Event): void {
      this.isFocus = !this.isFocus;
      this.$emit('inputBlur');
    },
    inputClick() {
      if (this.disabled || this.readonly) return;
      this.showList = true;
      if (this.type == 'datepicker') {
        this.showPicker = true;
      }
    },
    itemClick(item: any): void {
      if (! item.key && ! item.emit) return;
      this.$emit(item.emit ?? 'itemSelect', item);
      this.hideFeature();
    },
    togglePicker () {
      if (this.disabled || this.readonly) return;
      this.showPicker = !this.showPicker;
    },
    hideFeature(event: PointerEvent) {
      this.showList = false;
      this.showPicker = false;
    },
    emitter(item: any): void {
      if (! item.emit) return;
      this.$emit(item.emit, item);
    },
    selectDate(value: string) {
      this.inputValue = value;
      this.hideFeature();
    },
    browseFile() {
      this.$refs.input.click();
    },
    handleFileChange(event) {
      const fileInput = event.target;
      const selectedFiles = fileInput.files;

      if (selectedFiles.length > 0) {
        this.selectedFileName = selectedFiles[0].name;
        this.$emit('inputChange', selectedFiles[0]);
      } else {
        this.selectedFileName = null;
      }
    },
    clear() {
      if (this.disabled || this.readonly) return;
      this.inputValue = '';
      this.$emit('inputChange', this.inputValue);
      if (this.type != 'datepicker') { 
        this.$emit('update:value', this.inputValue);
      }
    }
  }
})

export default class Input extends Vue {
  type!: string;
  inputType!: string;
  inputValue!: string;
  value!: string;
  label!: string;
  labelitems!: any;
  placeholder!: string;
  description!: string;
  disabled!: boolean;
  readonly!: boolean;
  errors!: any;
  datalist!: item[];
  icon!: string;
  isFocus!: boolean;
  isShow!: boolean;
  togglePassword!: any;
  inputEvent!: any;
  blurEvent!: any;
  inputClick!: any;
  itemClick!: any;
  clear!: any;
  emitter!: any;
  delayTimer!: any;
  showList!: boolean;
  showPicker!: boolean;
  hideFeature!: any;
  selectDate!: any;
  maskOptions!: any;
  browseFile!: any;
  handleFileChange!: any;
  selectedFileName!: any;
  browseText!: any;
  unit!: string;
  datepickerHolder!: string;
  togglePicker!: any;
  user!: User;
  currencyComponent!: VueConstructor;
  currency!: string;
  currencyValue!: string;
  currencies!: CurrencyModel[];
  variableServices!: VariableServices
  

  data() {
    return {
      isFocus: false,
      isShow: false,
      showList: false,
      showPicker: false,
      inputType: this.type,
      inputValue: this.value,
      maskOptions: {},
      selectedFileExtension: null,
      selectedFileName: null,
      currencyComponent: null,
      currencyValue: this.currency,
      currencies: [],
      variableServices: VariableServices.getInstance(),
    }
  }
}

export interface item { key: number, name: string, info: string, class: string, data: any }
</script>

<style scoped>
.container {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.container .label {
  display: flex;
  align-items: center;
  gap: 2px;
  font-size: 12px;
  line-height: 16px;
  padding: 0 2px;
}
.container .info {
  height: 16px;
  width: 16px;
}
.container .info svg {
  width: 100%;
  height: 100%;
  stroke-width: 1.5px;
  stroke: var(--neutral800);
}
.container .info svg.required {
  animation: flash ease infinite 2s;
}
.container.required .info svg.required {
  stroke: var(--danger600);
}
.container label {
  color: var(--neutral800);
  font-weight: 500;
}
.container .label .items {
  display: flex;
  flex: 1;
}
.container .label .items span {
  font-weight: 400;
  color: var(--primary600);
  cursor: pointer;
}
.container .label .items .left {
  margin-right: auto;
}
.container .label .items .center {
  margin: 0 auto;
}
.container .label .items .right {
  margin-left: auto;
}
.container section {  
  display: flex;
  align-items: center;
  background-color: var(--white);
  border: 1px solid var(--neutral200);
  stroke: var(--neutral600);
  border-radius: 4px;
  overflow: hidden;
  padding: 0 15px;
  gap: 10px;
}
.container section.money {
  overflow: visible;
  padding-left: 0;
}
.container section:not(.disabled):hover,
.container section:not(.disabled).focus {
  border-color: var(--primary600);
  stroke: var(--neutral800);
}
.container section.error {
  border-color: var(--danger600);
}
.container section.error:hover,
.container section.focus.error,
.container section.focus.error:hover {
  border-color: var(--danger600);
}
.container section.disabled {
  background-color: var(--neutral150);
}
.container section.disabled * {
  cursor: default;
}
.container svg {
  width: 18px;
  height: 18px;
}
.container .hide-show, .container .icon {
  cursor: pointer;
}
.container .loading {
  animation: rotate linear infinite 1s;
}
.container input {
  flex: 1;
  font-family: 'Poppins', sans-serif;
  background-color: transparent;
  color: var(--neutral800);
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  border: 0;
  padding: 10px 0;
  outline: none;
  width: 100%;
}
.container input[type="date"]::-webkit-inner-spin-button,
.container input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}
.container input::placeholder {
  color: var(--neutral400);
}
.container input[type="password"]::placeholder {
  font-size: 14px;
}
.container section:not(.disabled):hover input::placeholder,
.container section:not(.disabled).focus input::placeholder {
  color: var(--neutral500);
}
.container section.disabled input {
  background-color: var(--neutral150);
}
.container .list {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: calc(100% - 10px);
  width: 100%;
  padding: 4px;
  background-color: var(--white);
  border: .5px solid var(--neutral150);
  box-shadow: 0px 1px 4px rgba(33, 33, 52, .1);
  border-radius: 4px;
  visibility: hidden;
  opacity: 0;
  z-index: -1;
  transition: all ease-in-out .1s;
}
.container .list.active {
  top: calc(100% + 2px);
  z-index: 1;
  visibility: visible;
  opacity: 1;
}
.container .list .item {
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  padding: 8px 10px;
  border-radius: 4px;
  gap: 4px;
  cursor: default;
}
.container .list .item:hover,
.container .list .item.add-button {
  background-color: var(--primary100);
}
.container .list .item.add-button:hover {
  background-color: var(--primary500);
  color: white;
}
.container .list .item.seperate {
  background-color: var(--neutral200);
  height: 1px;
  padding: 0;
  margin: 5px 0;
}
.container .list .item.not-found {
  color: var(--danger600);
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  background-color: inherit;
}
.container .list .item.title {
  color: var(--neutral400);
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  background-color: inherit;
  text-decoration: underline;
}
.container .list .item .info {
  margin-left: auto;
  font-size: 12px;
  color: var(--neutral400);
}
.container .input-description {
  color: var(--neutral600);
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  padding: 0 2px;
}
.container .input-errors {
  display: flex;
  flex-direction: column;
  min-height: 16px;
  gap: 2px;
}
.container .input-errors span {
  color: var(--danger600);
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  padding: 0 2px;
}
.container .file-container {
  display: flex;
  width: 100%;
  align-items: center;
  user-select: none;
}
.container .file-container .file {
  flex: 1;
  color: var(--shadowBlue);
  text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  font-size: 10px;
  font-family: Poppins;
  letter-spacing: -0.2px;
}
.container .file-container .file.selected {
  color: var(--neutral800);
}
.container .file-container .browse {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top: 0;
  border-bottom: 0;
  border-right: 0;
  border-left-color: var(--neutral200);
  height: 42px;
  margin-right: -15px;
}
.container .file-container .browse svg {
  min-width: 18px;
  min-height: 18px;
}
.container .unit {
  min-width: 30px;
  height: 40px;
  display: grid;
  place-content: center;
  border-left: 1px solid var(--neutral200);
  color: var(--neutral600);
  font-size: 12px;
  padding-left: 10px;
}
.container section .currency {
  width: 75px;
  border-right: 1px solid var(--neutral200);
}
</style>