import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/sprites/common-sprite.svg'


const _hoisted_1 = _imports_0 + '#arrow-right'
const _hoisted_2 = { class: "help" }
const _hoisted_3 = { class: "wrapper" }
const _hoisted_4 = { class: "form" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_input_component = _resolveComponent("input-component")!
  const _component_phone_component = _resolveComponent("phone-component")!
  const _component_select_component = _resolveComponent("select-component")!

  return (_openBlock(), _createElementBlock("div", _hoisted_2, [
    _createElementVNode("div", _hoisted_3, [
      _cache[8] || (_cache[8] = _createElementVNode("section", null, [
        _createElementVNode("h1", null, "Get started with us"),
        _createElementVNode("h3", null, "Don't hesitate to get started with us! Our team is ready to assist you 24/7. Simply fill out the form on the right, and we'll help you streamline your logistics operations today.")
      ], -1)),
      _createElementVNode("section", null, [
        _createElementVNode("div", _hoisted_4, [
          _cache[7] || (_cache[7] = _createElementVNode("label", null, "Ask a question", -1)),
          _createVNode(_component_input_component, {
            class: "input",
            placeholder: "Full Name",
            value: _ctx.info.full_name,
            "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.info.full_name) = $event)),
            errors: _ctx.validator.info.full_name.$errors
          }, null, 8, ["value", "errors"]),
          _createVNode(_component_input_component, {
            class: "input",
            placeholder: "Company Email",
            value: _ctx.info.company_email,
            "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.info.company_email) = $event)),
            errors: _ctx.validator.info.company_email.$errors
          }, null, 8, ["value", "errors"]),
          _createVNode(_component_input_component, {
            class: "input",
            placeholder: "Company Name",
            value: _ctx.info.company_name,
            "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.info.company_name) = $event)),
            errors: _ctx.validator.info.company_name.$errors
          }, null, 8, ["value", "errors"]),
          _createVNode(_component_phone_component, {
            class: "phone",
            placeholder: "00000000000",
            phone: _ctx.info.phone,
            "onUpdate:phone": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.info.phone) = $event)),
            errors: _ctx.validator.info.phone
          }, null, 8, ["phone", "errors"]),
          _createVNode(_component_select_component, {
            class: "select",
            placeholder: "Country",
            items: _ctx.countries,
            value: _ctx.info.country,
            "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.info.country) = $event)),
            errors: _ctx.validator.info.country.$errors,
            searchable: true
          }, null, 8, ["items", "value", "errors"]),
          _createElementVNode("button", {
            class: "primary large",
            onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.submit && _ctx.submit(...args)))
          }, _cache[6] || (_cache[6] = [
            _createElementVNode("span", null, "Talk with our team", -1),
            _createElementVNode("svg", null, [
              _createElementVNode("use", { "xlink:href": _hoisted_1 })
            ], -1)
          ]))
        ])
      ])
    ])
  ]))
}