import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "main" }
const _hoisted_2 = { class: "wrapper" }
const _hoisted_3 = { class: "item" }
const _hoisted_4 = { class: "item" }
const _hoisted_5 = {
  class: "port",
  autoplay: "",
  loop: "",
  muted: "",
  playsinline: ""
}
const _hoisted_6 = ["src"]
const _hoisted_7 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[1] || (_cache[1] = _createElementVNode("h1", null, "Stay on Top of Your Logistics Operations", -1)),
        _cache[2] || (_cache[2] = _createElementVNode("h4", null, "Track your shipments in real-time with our intuitive timeline view. Filter by status to focus on what's important.", -1)),
        _createElementVNode("button", {
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.shipments && _ctx.shipments(...args)))
        }, "Track Shipments")
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("video", _hoisted_5, [
          _createElementVNode("source", {
            src: require('@/assets/videos/main.webm'),
            type: "video/webm"
          }, null, 8, _hoisted_6),
          _createElementVNode("source", {
            src: require('@/assets/videos/main.mp4'),
            type: "video/mp4"
          }, null, 8, _hoisted_7)
        ])
      ])
    ])
  ]))
}