import { Phone } from "./user";

export class HelpModel { 
  full_name = '';
  company_email = '';
  company_name = '';
  phone: Phone = new Phone();
  country = 0;

  constructor(data: any = {}) {
    if (data == null) return;

    this.full_name = data.full_name || '';
    this.company_email = data.company_email || '';
    this.company_name = data.company_name || '';
    this.phone = new Phone(data.phone);
    this.country = data.country || 0;

  }
}