<template>
  <div class="contents">

    <div class="wrapper">
      <h3>Discover the Power of Smart Logistics</h3>

      <div class="item">
        <div class="image dashboard"></div>
        <div class="text">
          <h2>Manage Shipments Effortlessly</h2>
          <h4>Access detailed shipment data, filter by status, and update records in just a few clicks.</h4>
          <button @click="shipments">Add New Shipment</button>
        </div>
      </div>

      <div class="item">
        <div class="image quote"></div>
        <div class="text">
          <h2>Streamline Your Quote Management</h2>
          <h4>Create and track shipping quotes effortlessly, with real-time updates on status.</h4>
          <button @click="quotes">Request a Quote</button>
        </div>
      </div>
      
      <div class="item">
        <div class="image document"></div>
        <div class="text">
          <h2>Document Management</h2>
          <h4>Organize and access your MSDS, CO, and other critical documents with ease.</h4>
          <button @click="documents">Upload New Document</button>
        </div>
      </div>

      <div class="item">
        <div class="image shipment"></div>
        <div class="text">
          <h2>Simplify Shipment Updates</h2>
          <h4>Edit delivery details, assign agents, and streamline booking with a few clicks.</h4>
          <button @click="shipments">Save Changes</button>
        </div>
      </div>

      <div class="item">
        <div class="image request"></div>
        <div class="text">
          <h2>Request a Quote in Minutes</h2>
          <h4>Specify your shipping requirements and get tailored quotes instantly.</h4>
          <button @click="quotes">Send Request</button>
        </div>
      </div>

    </div>
    
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

@Options({
  computed: {
    baseDomain () {
      const host = window.location.host;
      const domain = host.includes('.') ? host.split('.').slice(-2).join('.') : host;
      return domain;
    }
  },
  methods: {
    shipments () {
      window.open(`//app.${this.baseDomain}/shipments`, '_blank');
    },
    quotes () {
      window.open(`//app.${this.baseDomain}/quotes`, '_blank');
    },
    documents () {
      window.open(`//app.${this.baseDomain}/documents`, '_blank');
    }
  }
})

export default class ContentArea extends Vue {
  shipments!: any;
  quotes!: any;
  documents!: any;
}
</script>

<style scoped>
  .contents {
    padding: 80px 0;
  }
  .wrapper {
    display: flex;
    flex-direction: column;
    gap: 80px;
  }
  .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 40px;
  }
  .item:nth-child(even) {
    flex-direction: row-reverse;
  }
  h2 {
    color: var(--neutral1000);
    font-size: 56px;
    font-weight: 600;
    line-height: 64px;
    transition: all .25s;
  }
  h3 {
    font-size: 32px;
    text-align: center;
    transition: all .25s;
  }
  h4 {
    color: var(--neutral1000);
    font-size: 20px;
    line-height: 24px;
    transition: all .25s;
  }
  button {
    font-size: 16px;
    line-height: 22px;
    padding: 14px 24px;
    background-color: var(--black);
    border-radius: 24px;
    color: var(--white);
    transition: all .25s;
  }
  .text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    width: 50%;
    z-index: 1;
  }
  .image {
    width: 50%;
    height: 460px;
    background-size: contain;
    background-repeat: no-repeat;
  }
  .item:nth-child(odd) .image {
    background-position: left;
  }
  .item:nth-child(even) .image {
    background-position: right;
  }
  .image.dashboard {
    background-image: url('/src/assets/images/marketings/dashboard.svg');
  }
  .image.quote {
    background-image: url('/src/assets/images/marketings/quote.svg');
  }
  .image.document {
    background-image: url('/src/assets/images/marketings/document.svg');
  }
  .image.shipment {
    background-image: url('/src/assets/images/marketings/shipment.svg');
  }
  .image.request {
    background-image: url('/src/assets/images/marketings/request.svg');
  }

  @media screen and (max-width: 1024px) {
    .wrapper {
      gap: 0;
    }
    h2 {
      font-size: 36px;
      line-height: normal;
    }
    h3 {
      font-size: 22px;
    }
    h4 {
      font-size: 16px;
      line-height: normal;
    }
    button {
      font-size: 14px;
      padding: 12px 20px;
    }
  }
  @media screen and (max-width: 800px) {
    .wrapper {
      gap: 40px;
    }
    h2 {
      font-size: 30px;
      line-height: normal;
    }
    h3 {
      font-size: 16px;
    }
    h4 {
      font-size: 14px;
      line-height: normal;
    }
    button {
      font-size: 12px;
      padding: 10px 18px;
    }
    .item {
      flex-direction: column-reverse !important;
    }
    .text, .image {
      width: 100%;
      align-items: center;
      background-position: center !important;
    }
    .image {
      height: 25vh;
    }
  }
</style>