<template>
  <div class="testimonials">
    <h4>Testimonials</h4>
    <h2>Trusted by Industry Leaders</h2>
    <div class="wrapper">
      <swiper class="slider" :modules="modules" :pagination="pagination" :loop="true" :space-between="25" :breakpoints="breakpoints">
        <swiper-slide>
          <div class="item">
            <blockquote>“With Logictics, we reduced our shipment delays by 25%. The real-time tracking feature has been a game-changer for our operations!”</blockquote>
            <div class="person">
              <div class="photo"></div>
              <div class="info">
                <span class="name">Mark T.</span>
                <span class="title">Logistics Manager at Speedline Freight</span>
              </div>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="item">
            <blockquote>"Managing our supply chain used to be chaotic. Thanks to Logictics, we now have full visibility and control over our shipments."</blockquote>
            <div class="person">
              <div class="photo"></div>
              <div class="info">
                <span class="name">Anna K.</span>
                <span class="title">CEO of TrendyDepot</span>
              </div>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="item">
            <blockquote>“The seamless integration with our existing ERP system made the transition to Logictics effortless. Highly recommended!”</blockquote>
            <div class="person">
              <div class="photo"></div>
              <div class="info">
                <span class="name">David L.</span>
                <span class="title">Owner of Green Fields Logistics</span>
              </div>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="item">
            <blockquote>"Logictics' route optimization feature helped us cut transportation costs by 30%. It's an invaluable tool for our team."</blockquote>
            <div class="person">
              <div class="photo"></div>
              <div class="info">
                <span class="name">Sophia R.</span>
                <span class="title">Operations Director at GlobalShip</span>
              </div>
            </div>
          </div>
        </swiper-slide>
        <div class="pagination"></div>
      </swiper>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Pagination, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';

@Options({
  components: { Swiper, SwiperSlide },
})

export default class Testimonials extends Vue {
  modules!: any;
  pagination!: any;
  breakpoints!: any

  data() {
    return {
      pagination: {
        el: '.pagination',
        clickable: true,
        renderBullet: (index: number, className: string) => '<span class="' + className + '"></span>'
      },
      modules: [Pagination, A11y],
      breakpoints: {
        600: { slidesPerView: 1 },
        800: { slidesPerView: 2 },
        1000: { slidesPerView: 3 },
        1200: { slidesPerView: 4 },
      }
    }
  }
}
</script>

<style scoped>
  .testimonials {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    background-color: #414C66;
    padding: 40px 0;
  }
  .wrapper {
    padding: 30px 0;
  }
  h2 {
    color: var(--white);
    font-size: 40px;
    font-weight: 600;
    text-align: center;
    transition: all .25s;
  }
  h4 {
    color: #00B7C6;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    transition: all .25s;
  }
  .slider .item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 300px;
    background-color: rgba(255,255,255,.06);
    backdrop-filter: blur(5px);
    border-radius: 16px;
    padding: 20px;
    color: var(--white);
  }
  .slider .item blockquote {
    font-family: 'Poppins', sans-serif;
    font-size: 15px;
    line-height: 24px;
    padding: 5px 10px;
  }
  .slider .item .person {
    display: flex;
    align-items: center;
    border-radius: 48px;
    border: 1px solid #00B7C6;
    padding: 15px;
    gap: 10px
  }
  .slider .item .person .photo {
    width: 50px;
    height: 50px;
    min-width: 50px;
    min-height: 50px;
    border-radius: 50%;
    overflow: hidden;
    background-color: #00B7C6;
  }
  .slider .item .person .info {
    display: flex;
    flex-direction: column;
    gap: 2px;
  }
  .slider .item .person .info .name {
    font-size: 14px;
    line-height: 18px;
    font-weight: 600;
  }
  .slider .item .person .info .title {
    font-size: 12px;
    line-height: 16px;
    color: var(--neutral300);
  }
  .slider .pagination {
    display: flex;
    gap: 10px;
    margin: 40px 0 0 0;
    justify-content: center;
  }
  .slider .pagination.bottom {
    position: relative;
    bottom: 50px;
    z-index: 1;
  }
  .slider .pagination :deep(span) {
    width: 10px;
    height: 10px;
    background-color: var(--neutral500);
    border-radius: 5px;
    cursor: pointer;
    transition: all .5s;
  }
  .slider .pagination :deep(span[class$='-active']) {
    width: 33px;
    background-color: #00B7C6;
  }
  @media screen and (max-width: 1024px) {
    h2 {
      font-size: 32px;
    }
  }
  @media screen and (max-width: 800px) {
    h2 {
      font-size: 24px;
    }
    h4 {
      font-size: 14px;
    }
  }
</style>