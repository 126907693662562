<template>
  <div class="footer">
    <div class="wrapper">
      
      <div class="top">

        <section>
          <div class="logo">
            <svg class="text"><use xlink:href="@/assets/images/sprites/common-sprite.svg#app-logo-text-2"></use></svg>
            <svg class="image"><use xlink:href="@/assets/images/sprites/common-sprite.svg#app-logo-image"></use></svg>
          </div>
          <span class="copyright">2024 © Logictics. All rights reserved.</span>
        </section>

        <section>
          <svg class="social"><use xlink:href="@/assets/images/sprites/common-sprite.svg#social-icon-facebook"></use></svg>
          <svg class="social"><use xlink:href="@/assets/images/sprites/common-sprite.svg#social-icon-instagram"></use></svg>
          <svg class="social"><use xlink:href="@/assets/images/sprites/common-sprite.svg#social-icon-x"></use></svg>
          <svg class="social"><use xlink:href="@/assets/images/sprites/common-sprite.svg#social-icon-linkedin"></use></svg>
          <svg class="social"><use xlink:href="@/assets/images/sprites/common-sprite.svg#social-icon-telegram"></use></svg>
        </section>

      </div>

      <div class="middle">

        <section>
          <span class="title">Why Logictics</span>
          <ul>
            <li>Overview</li>
            <li>Key Benefits</li>
            <li>Industry Challenges</li>
          </ul>
        </section>

        <section>
          <span class="title">Features</span>
          <ul>
            <li>Real-Time Tracking</li>
            <li>Smart Route Planning</li>
            <li>Seamless Integration</li>
            <li>Data Analytics</li>
          </ul>
        </section>

        <section>
          <span class="title">Use Cases</span>
          <ul>
            <li>Warehouse Management</li>
            <li>Fleet Optimization</li>
            <li>Supply Chain Transparency</li>
            <li>Delivery Operations</li>
          </ul>
        </section>

        <section>
          <span class="title">Pricing</span>
        </section>

        <section>
          <span class="title">About us</span>
          <ul>
            <li>Company</li>
            <li>Careers</li>
            <li>Blog</li>
            <li>Contact</li>
            <li>Legal & Privacy</li>
          </ul>
        </section>

      </div>

      <div class="bottom">
        Founded in 2023, Logictics is a trusted logistics platform designed to optimize and streamline supply chain operations globally. Logictics helps businesses of all sizes manage their logistics needs, from a single shipment to large-scale operations, with ease. With cutting-edge technology, real-time tracking, and 24/7 customer support, Logictics is the simplest way to manage shipments, optimize routes, and collaborate with partners worldwide. Whether you're shipping goods, managing fleets, or ensuring warehouse efficiency, Logictics is your all-in-one logistics solution.
      </div>

    </div>
  </div>
</template>

<script lang="ts">
import { Vue } from 'vue-class-component';
export default class Foot extends Vue {}
</script>

<style scoped>
  .footer {
    background-color: #414C66;
  }
  .wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 10px;
  }
  .top {
    display: flex;
    justify-content: space-between;
  }
  .top section {
    display: flex;
    align-items: center;
    gap: 10px;
    flex: 1;
  }
  .top section:last-child {
    justify-content: flex-end;
  }
  .top section .logo {
    display: flex;
  }
  .top section .logo .text {
    width: 115px;
    height: 50px;
  }
  .top section .logo .image {
    width: 35px;
    height: 50px;
  }
  .top section .copyright {
    width: 95px;
    font-size: 11px;
    font-weight: 300;
    line-height: 16px;
    color: var(--white);
  }
  .top section .social {
    width: 36px;
    height: 36px;
  }

  .middle {
    display: flex;
    justify-content: space-between;
  }
  .middle section {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .middle section .title {
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    color: var(--white);
  }
  .middle section ul li {
    font-size: 14px;
    font-weight: 400;
    line-height: 34px;
    color: var(--white);
  }

  .bottom::before {
    position: absolute;
    content: "";
    width: 30%;
    height: 1px;
    top: 0;
    left: 35%;
    background-color: var(--white);
  }
  .bottom {
    position: relative;
    font-size: 12px;
    font-weight: 400;
    color: var(--white);
    line-height: 16px;
    padding: 20px 0 40px 0;
    text-align: center;
  }
  .bottom::after {
    position: absolute;
    content: "© 2024 Logictics";
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  @media screen and (max-width: 800px) {
    .top {
      flex-direction: column;
      gap: 10px;
    }
    .top section:last-child {
      justify-content: flex-start;
    }
    .middle {
      display: none;
    }
    .bottom::before {
      width: 100%;
      left: 0;
    }
  }
</style>