import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, vShow as _vShow, withDirectives as _withDirectives, withKeys as _withKeys, resolveDirective as _resolveDirective } from "vue"
import _imports_0 from '@/assets/images/sprites/common-sprite.svg'


const _hoisted_1 = _imports_0 + '#info-icon'
const _hoisted_2 = _imports_0 + '#cross-icon'
const _hoisted_3 = _imports_0 + '#arrow-icon'
const _hoisted_4 = _imports_0 + '#down-arrow-icon'
const _hoisted_5 = {
  key: 0,
  class: "label"
}
const _hoisted_6 = {
  key: 0,
  class: "info",
  "tooltip-right": "Required information"
}
const _hoisted_7 = {
  key: 0,
  class: "icon"
}
const _hoisted_8 = ["xlink:href"]
const _hoisted_9 = { class: "placeholder" }
const _hoisted_10 = {
  key: 2,
  class: "arrow"
}
const _hoisted_11 = { class: "list" }
const _hoisted_12 = {
  key: 0,
  class: "searchbar"
}
const _hoisted_13 = { class: "items" }
const _hoisted_14 = ["onClick"]
const _hoisted_15 = {
  key: 0,
  class: "icon"
}
const _hoisted_16 = ["xlink:href"]
const _hoisted_17 = { class: "text" }
const _hoisted_18 = {
  key: 1,
  class: "code"
}
const _hoisted_19 = ["onClick"]
const _hoisted_20 = { class: "grup-name" }
const _hoisted_21 = { class: "sub-items" }
const _hoisted_22 = ["onClick"]
const _hoisted_23 = { class: "text" }
const _hoisted_24 = {
  key: 1,
  class: "description"
}
const _hoisted_25 = {
  key: 2,
  class: "input-errors"
}
const _hoisted_26 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_input_component = _resolveComponent("input-component")!
  const _directive_click_away = _resolveDirective("click-away")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["container", { required: _ctx.required }])
  }, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          (_ctx.required)
            ? (_openBlock(), _createElementBlock("span", _hoisted_6, _cache[5] || (_cache[5] = [
                _createElementVNode("svg", { class: "required" }, [
                  _createElementVNode("use", { "xlink:href": _hoisted_1 })
                ], -1)
              ])))
            : _createCommentVNode("", true),
          _createElementVNode("label", null, _toDisplayString(_ctx.label), 1)
        ]))
      : _createCommentVNode("", true),
    _withDirectives((_openBlock(), _createElementBlock("section", {
      onKeyup: _cache[3] || (_cache[3] = _withKeys(
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)), ["esc"])),
      onKeydown: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.keyHelper && _ctx.keyHelper(...args))),
      class: _normalizeClass({active: _ctx.isActive, error: _ctx.errors && _ctx.errors.length, disabled: _ctx.disabled, searchable: _ctx.searchable})
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(["selective", {focus: _ctx.isFocus, selected: _ctx.selectedItem}]),
        tabindex: "0",
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.open && _ctx.open(...args)))
      }, [
        (_ctx.selectedItem && _ctx.selectedItem.icon)
          ? (_openBlock(), _createElementBlock("svg", _hoisted_7, [
              _createElementVNode("use", {
                "xlink:href": _ctx.iconset + _ctx.selectedItem.icon
              }, null, 8, _hoisted_8)
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.selectedItem ? (_ctx.selectedItem.code ? _ctx.selectedItem.code : _ctx.selectedItem.name) : _ctx.placeholder), 1),
        (_ctx.selectedItem && !_ctx.disabled && _ctx.removable)
          ? (_openBlock(), _createElementBlock("svg", {
              key: 1,
              class: "cross",
              onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.removeItem && _ctx.removeItem(...args)), ["stop"]))
            }, _cache[6] || (_cache[6] = [
              _createElementVNode("use", { "xlink:href": _hoisted_2 }, null, -1)
            ])))
          : (_openBlock(), _createElementBlock("svg", _hoisted_10, _cache[7] || (_cache[7] = [
              _createElementVNode("use", { "xlink:href": _hoisted_3 }, null, -1)
            ])))
      ], 2),
      _createElementVNode("div", _hoisted_11, [
        (_ctx.searchable)
          ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
              _createVNode(_component_input_component, {
                placeholder: "Search in list",
                value: _ctx.searchText,
                "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.searchText) = $event))
              }, null, 8, ["value"])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_13, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: _normalizeClass(["item", [item.class, { selected: _ctx.selectedItem && _ctx.selectedItem.key == item.key, hide: _ctx.isHide(item.name, item.code), disabled: item.disabled }]]),
              ref_for: true,
              ref: "listItem",
              key: index,
              onClick: ($event: any) => (_ctx.selectItem(item))
            }, [
              (item.icon)
                ? (_openBlock(), _createElementBlock("svg", _hoisted_15, [
                    _createElementVNode("use", {
                      "xlink:href": _ctx.iconset + item.icon
                    }, null, 8, _hoisted_16)
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("span", _hoisted_17, _toDisplayString(item.name), 1),
              (item.code)
                ? (_openBlock(), _createElementBlock("span", _hoisted_18, "(" + _toDisplayString(item.code) + ")", 1))
                : _createCommentVNode("", true)
            ], 10, _hoisted_14))
          }), 128)),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.grouplist, (group, name) => {
            return (_openBlock(), _createElementBlock("div", { key: name }, [
              _createElementVNode("h2", {
                class: _normalizeClass(["item title", { 'hoverable': group.show }]),
                onClick: ($event: any) => (_ctx.toggleDropdown(group))
              }, [
                _createElementVNode("span", _hoisted_20, _toDisplayString(name), 1),
                (group.show)
                  ? (_openBlock(), _createElementBlock("svg", {
                      key: 0,
                      class: _normalizeClass(["down-arrow", { rotate: group.state }])
                    }, _cache[8] || (_cache[8] = [
                      _createElementVNode("use", { "xlink:href": _hoisted_4 }, null, -1)
                    ]), 2))
                  : _createCommentVNode("", true)
              ], 10, _hoisted_19),
              _withDirectives(_createElementVNode("div", _hoisted_21, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(group.items, (item, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: _normalizeClass(["item", [item.class, { selected: _ctx.selectedItem && _ctx.selectedItem.key == item.key, hide: _ctx.isHide(item.name, item.code), disabled: item.disabled }]]),
                    ref_for: true,
                    ref: "listItem",
                    key: index,
                    onClick: ($event: any) => (_ctx.selectItem(item))
                  }, [
                    _createElementVNode("span", _hoisted_23, _toDisplayString(item.name), 1)
                  ], 10, _hoisted_22))
                }), 128))
              ], 512), [
                [_vShow, group.state]
              ])
            ]))
          }), 128))
        ])
      ])
    ], 34)), [
      [_directive_click_away, _ctx.close]
    ]),
    (_ctx.description)
      ? (_openBlock(), _createElementBlock("span", _hoisted_24, _toDisplayString(_ctx.description), 1))
      : _createCommentVNode("", true),
    (_ctx.errors && _ctx.errors.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.errors, (error) => {
            return (_openBlock(), _createElementBlock(_Fragment, {
              key: error.$uid
            }, [
              (error.$message != '')
                ? (_openBlock(), _createElementBlock("span", _hoisted_26, _toDisplayString(error.$message), 1))
                : _createCommentVNode("", true)
            ], 64))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ], 2))
}