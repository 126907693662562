<template>
  <div class="overlay" :class="{ closing: closing }" v-if="status">
    <div class="modal-wrapper">
      <div class="content" v-click-away="close" @keyup.esc="close">
        <slot name="content" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component';

@Options({
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    show(current, old) {
      if (this.closing) return;
      if (!old && current) this.status = true;
      if (old && !current) this.close()
    }
  },
  methods: {
    close() {
      this.closing = true;
      setTimeout(() => {
        this.closing = false;
        this.status = false;
        this.$emit('update:show', false);
      }, 250);
    },
  },
})

export default class Modal extends Vue {
  status!: boolean;
  show!: boolean;
  closing!: boolean;
  close!: any;

  data() {
    return {
      status: this.show,
      closing: false
    }
  }
}

</script>

<style scoped>
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(5px);
  z-index: 99;
  overflow-y: auto;
  animation: blur ease .5s forwards;
}
.overlay.closing {
  animation: sharp ease .5s forwards;
}
.modal-wrapper {
  position: relative;
  display: grid;
  place-content: center;
  min-height: 100%;
  padding: 40px;
  animation: slide-down ease .5s forwards;
}
.closing .modal-wrapper {
  animation: slide-up ease .5s forwards;
}
.content {
  min-width: 100px;
  min-height: 100px;
  background-color: var(--white);
  border: 1px solid var(--neutral200);
  border-radius: 10px;
  /* padding: 30px 25px; */
  box-shadow: 0px 0px 20px -5px rgba(65, 76, 102, 0.25);
}
.modal-wrapper .content :deep(.title) {
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: var(--neutral800);
}

@keyframes blur {
  0% { backdrop-filter: blur(0px); }
  100% { backdrop-filter: blur(5px); }
}
@keyframes sharp {
  0% { backdrop-filter: blur(5px); }
  100% { backdrop-filter: blur(0px); }
}
@keyframes slide-down {
  0% { top: -100%; }
  100% { top: 0; }
}
@keyframes slide-up {
  0% { top: 0%; }
  100% { top: -100%; }
}
</style>