import ApiServices from "./Api";

export class MapsServices extends ApiServices {
  private static instance?: MapsServices;

  public static getInstance(): MapsServices {
    if (! this.instance) this.instance = new MapsServices();
    return this.instance;
  }

  public countries = (info: countriesInfo) => this.api.get(`/service/maps/countries?${new URLSearchParams(Object(info))}`);
  public states = (info: statesInfo) => this.api.get(`/service/maps/states?${new URLSearchParams(Object(info))}`);
  public cities = (info: citiesInfo) => this.api.get(`/service/maps/cities?${new URLSearchParams(Object(info))}`);
  public autocomplete = (input: string) => this.api.get(`/service/maps/autocomplete?input=${input}`);
  public find = (info: findInfo) => this.api.get(`/service/maps/find?${new URLSearchParams(Object(info))}`);
  public ports = (info: portsInfo) => this.api.get(`/service/maps/ports?${new URLSearchParams(Object(info))}`);
}

export interface countriesInfo { id: string, name: string }
export interface statesInfo { id: string, country_id: string, name: string }
export interface citiesInfo { id: string, state_id: string, name: string }
export interface findInfo { place_id: string, latlng: string }
export interface portsInfo { name: string, company: string }

//service/maps/ports?search=TRK&type=sea