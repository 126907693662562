<template>
  <div class="datepicker" :class="{ active: active }">
    <div class="parent-wrapper">
      <div class="parent months" :class="{ active: isMonthChoose }">
        <svg class="arrow-icon" @click="prevMonth"><use xlink:href="@/assets/images/sprites/common-sprite.svg#left-arrow-icon"></use></svg>
        <span class="selected" @click="isMonthChoose = !isMonthChoose">{{ getMonth().name }}</span>
        <div class="list">
          <svg class="cross-icon" @click="isMonthChoose = !isMonthChoose"><use xlink:href="@/assets/images/sprites/common-sprite.svg#cross-icon"></use></svg>
          <div class="item" v-for="month in months" :key="month.key" :class="{ active: month.key == getMonth().key }" @click="setMonth(month)">
            <span>{{ month.name }}</span>
          </div>
        </div>
        <svg class="arrow-icon" @click="nextMonth"><use xlink:href="@/assets/images/sprites/common-sprite.svg#right-arrow-icon"></use></svg>
      </div>
      <div class="parent years" :class="{ active: isYearChoose }">
        <svg class="arrow-icon" @click="prevYear"><use xlink:href="@/assets/images/sprites/common-sprite.svg#left-arrow-icon"></use></svg>
        <span class="selected" @click="chooseYear">{{ _default.getFullYear() }}</span>
        <div class="list">
          <svg class="cross-icon" @click="isYearChoose = !isYearChoose"><use xlink:href="@/assets/images/sprites/common-sprite.svg#cross-icon"></use></svg>
          <div class="item" ref="yearItems" v-for="index in 20" :key="index" :class="{ active: getYear(index) == _default.getFullYear() }" @click="setYear(getYear(index))">
            <span>{{ getYear(index) }}</span>
          </div>
        </div>
        <svg class="arrow-icon" @click="nextYear"><use xlink:href="@/assets/images/sprites/common-sprite.svg#right-arrow-icon"></use></svg>
      </div>
    </div>
    <div class="week-wrapper">
      <div class="header">
        <div class="day" v-for="day in days" :key="day.key">{{ day.short }}</div>
      </div>
      <div class="body">
        <div class="week" v-for="week in 6" :key="week">
          <div class="day" v-for="day in days" :key="day.key" :class="{ 'selected-month': isSelectedMonth(week, day), 'current-month': isCurrentMonth(week, day), 'current-day': isCurrentDay(week, day), 'active': isActiveDay(week, day) }" @click="selectDay(week, day)">{{ getDateOfWeek(week, day).getDate() }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import moment from 'moment';
import { User } from "@/models/commons/user";

@Options({
  props: {
    date: { type: String, default: '' },
    active: { type: Boolean, default: false }
  },
  watch: {
    date() { this.setDate(); }
  },
  created() {
    this.setDate();
  },
  computed: {
    user(): User { return new User(this.$store.state.user) },
  },
  methods: {
    setDate () {
      const date = moment(this.date, this.user.date_format.id).toDate();
      if (! isNaN(date.getTime())) {
        this.selected = true;
        this._default = moment(this.date, this.user.date_format.id).toDate();
        this.selectedDate = moment(this.date, this.user.date_format.id).toDate();
      }
      else {
        this.selected = false;
      }
    },
    isSelectedMonth (week: number, day: dateItem): boolean {
      return this.getDateOfWeek(week, day).getMonth() == this._default.getMonth();
    },
    isCurrentMonth (week: number, day: dateItem): boolean {
      return this.getDateOfWeek(week, day).getMonth() == this.now.getMonth();
    },
    isCurrentDay (week: number, day: dateItem): boolean {
      return this.getDateOfWeek(week, day).toDateString() == this.now.toDateString();
    },
    isActiveDay (week: number, day: dateItem): boolean {
      if (! this.selectedDate) return false;
      return this.getDateOfWeek(week, day).toDateString() == this.selectedDate.toDateString();
    },
    getDateOfWeek (week: number, day: dateItem): Date {
      const firstDayOfMonth: Date = new Date(this._default.getFullYear(), this._default.getMonth(), 1);
      const monthOffset: number = -(firstDayOfMonth.getDay() % 7) + 1;
      const dayOffset: number = ((week - 1) * 7) + day.key;
      return new Date(this._default.getFullYear(), this._default.getMonth(), monthOffset + dayOffset);
    },
    chooseYear () {
      this.isYearChoose = ! this.isYearChoose;
      const activeItem = this.$refs.yearItems.find((item) => item.classList.contains('active'));
      if (activeItem) activeItem.scrollIntoView({block: "nearest"});
    },
    getYear(index: number): number {
      return (this._default.getFullYear() - 10) + index;
    },
    getMonth(): dateItem {
      return this.months.find((item: dateItem) => item.key == this._default.getMonth());
    },
    selectDay (week: number, day: dateItem) {
      this.selectedDate = this.getDateOfWeek(week, day);
      this.$emit('selectDate', moment(this.selectedDate).format(this.user.date_format.id));
    },
    setYear (year: number) {
      this._default.setYear(year);
      this.isYearChoose = ! this.isYearChoose;
    },
    prevMonth () {
      this.isMonthChoose = ! this.isMonthChoose;
      this._default.setMonth(this._default.getMonth() - 1);
      this.isMonthChoose = ! this.isMonthChoose;
    },
    nextMonth () {
      this.isMonthChoose = ! this.isMonthChoose;
      this._default.setMonth(this._default.getMonth() + 1);
      this.isMonthChoose = ! this.isMonthChoose;
    },
    prevYear () {
      this.isYearChoose = ! this.isYearChoose;
      this._default.setYear(this._default.getFullYear() - 1);
      this.isYearChoose = ! this.isYearChoose;
    },
    nextYear () {
      this.isYearChoose = ! this.isYearChoose;
      this._default.setFullYear(this._default.getFullYear() + 1);
      this.isYearChoose = ! this.isYearChoose;
    },
    setMonth (month: dateItem) {
      this._default.setMonth(month.key);
      this.isMonthChoose = ! this.isMonthChoose;
    },
  }
})

export default class DatePicker extends Vue {
  active!: boolean;
  now!: Date;
  _default!: Date;
  selectDate!: Date;
  selected!: boolean;
  months!: dateItem[];
  days!: dateItem[];

  getYear!: any;
  getMonth!: any;
  getDateOfWeek!: any;
  isSelectedMonth!: any;
  isCurrentMonth!: any;
  isCurrentDay!: any;
  isActiveDay!: any;
  selectDay!: any;
  chooseYear!: any;
  isYearChoose!: boolean;
  setYear!: any;
  isMonthChoose!: boolean;
  setMonth!: any;
  prevMonth!: any;
  nextMonth!: any;
  prevYear!: any;
  nextYear!: any;

  data() {
    return {
      now: new Date(),
      _default: new Date(),
      selectedDate: null,
      selected: false,
      isYearChoose: false,
      isMonthChoose: false,
      months: [
        { key: 0, name: "January", short: "Jan" },
        { key: 1, name: "February", short: "Feb" },
        { key: 2, name: "March", short: "Mar" },
        { key: 3, name: "April", short: "Apr" },
        { key: 4, name: "May", short: "May" },
        { key: 5, name: "June", short: "Jun" },
        { key: 6, name: "July", short: "Jul" },
        { key: 7, name: "August", short: "Aug" },
        { key: 8, name: "September", short: "Sep" },
        { key: 9, name: "October", short: "Oct" },
        { key: 10, name: "November", short: "Nov" },
        { key: 11, name: "December", short: "Dec" }
      ],
      days: [
        { key: 0, name: "Sunday", short: "Su" },
        { key: 1, name: "Monday", short: "Mo" },
        { key: 2, name: "Tuesday", short: "Tu" },
        { key: 3, name: "Wednesday", short: "We" },
        { key: 4, name: "Thursday", short: "Th" },
        { key: 5, name: "Friday", short: "Fr" },
        { key: 6, name: "Saturday", short: "Sa" },
        
      ]
    }
  }
}

export interface dateItem { key: number, name: string, short: string }

</script>

<style scoped>
.datepicker {
  display: flex;
  flex-direction: column;
  gap: 15px;
  position: absolute;
  top: calc(100% - 10px);
  padding: 15px;
  background-color: var(--white);
  border: .5px solid var(--neutral150);
  box-shadow: 0px 1px 4px rgba(33, 33, 52, .1);
  border-radius: 4px;
  visibility: hidden;
  opacity: 0;
  z-index: -1;
  transition: all ease-in-out .1s;
  overflow: hidden;
  user-select: none;
}
.datepicker.active {
  top: calc(100% + 2px);
  z-index: 1;
  visibility: visible;
  opacity: 1;
}
.datepicker .parent-wrapper {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}
.datepicker .parent-wrapper .arrow-icon,
.datepicker .parent-wrapper .cross-icon {
  width: 18px;
  height: 18px;
  cursor: pointer;
  stroke: var(--neutral900);
}
.datepicker .parent-wrapper .cross-icon {
  position: absolute;
  right: 10px;
  top: 10px;
}
.datepicker .parent-wrapper .parent {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
}
.datepicker .parent-wrapper .parent .selected {
  font-size: 14px;
  line-height: 16px;
  font-weight: 700;
  cursor: pointer;
  color: var(--primary400);
}
.datepicker .parent-wrapper .parent .list {
  position: absolute;
  display: grid;
  background-color: var(--white);
  font-size: 14px;
  left: 0;
  top: -100%;
  width: 100%;
  height: 100%;
  padding: 30px 10px 10px 10px;
  transition: all .25s;
}
.datepicker .parent-wrapper .parent.months .list {
  grid-template-columns: repeat(3, 1fr);
}
.datepicker .parent-wrapper .parent.years .list {
  grid-template-columns: repeat(4, 1fr);
}
.datepicker .parent-wrapper .parent.active .list {
  top: 0;
}
.datepicker .parent-wrapper .parent .list .item {
  display: grid;
  place-content: center;
}
.datepicker .parent-wrapper .parent .list .item span {
  padding: 5px 10px;
  color: var(--neutral900);
  border-radius: 4px;
  cursor: pointer;
}
.datepicker .parent-wrapper .parent .list .item i {
  font-size: 12px;
}
.datepicker .parent-wrapper .parent .list .item.active span {
  background-color: var(--primary100);
  font-weight: 700;
  color: var(--primary600);
}
.datepicker .parent-wrapper .parent .list .item span:hover {
  background-color: var(--primary100);
}
.datepicker .week-wrapper {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  line-height: 16px;
}
.datepicker .week-wrapper .header {
  display: flex;
  font-weight: 700;
  gap: 4px;
}
.datepicker .week-wrapper .body {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.datepicker .week-wrapper .week {
  display: flex;
  gap: 4px;
}
.datepicker .week-wrapper .day {
  display: grid;
  place-content: center;
  width: 32px;
  height: 32px;
  color: var(--neutral900);
  border-radius: 4px;
  cursor: default;
}
.datepicker .week-wrapper .day:not(.selected-month) {
  color: var(--neutral600);
}
.datepicker .week-wrapper .day.current-day {
  color: var(--primary600);
}
.datepicker .week-wrapper .day.active {
  background-color: var(--primary100);
  font-weight: 700;
  color: var(--primary600);
}
.datepicker .week-wrapper .week .day:not(.active):hover {
  border: 1px solid var(--neutral300);
  cursor: pointer;
}

</style>