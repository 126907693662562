<template>
  <onboarding-layout title="Sign Up">
    <template v-slot:slider>
      <slider-component class="slider" :slider="slider" pagerPosition="center bottom" />
    </template>
    <template v-slot:form>
      <router-view />
    </template>
  </onboarding-layout>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import OnboardingLayout from '@/components/layouts/Onboarding.vue';
import Slider from '@/components/shared/Slider.vue';
import axios from 'axios';

@Options({
  components: {
    'onboarding-layout': OnboardingLayout,
    'slider-component': Slider,
  },
  async created() {
    try {
      const response = await axios.get("https://api.pexels.com/v1/search", {
        headers: { 
          Authorization: 'f5F5cTpDKyTKgoLEBnPpTn6BM1WhGuieeDaLsKqPI1UiTMdhgjNqLq5Q',
        },
        params: {
          query: 'cargo ship',
          per_page: 5,
        },
      });
      this.slider = response.data.photos.map(photo => { return { id: photo.id, caption: 'asdasd', image: photo.src.portrait }; });
    } catch (error) {
      console.error("Error fetching images:", error);
    } finally {
      this.loading = false;
    }
  }
})

export default class RegisterIndex extends Vue {
  slider!: [any];

  data() {
    return {
      slider: []
    }
  }
}
</script>

<style scoped>
.slider-container .slider {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
.slider-container img {
  position: absolute;
  left: 0;
  bottom: 0;
  max-width: 100%;
  height: 50%;
  object-fit: contain;
  object-position: bottom;
  z-index: 0;
}
.slider-container .slider :deep(.item::after) {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  content: "";
  background-color: rgba(0,0,0,.35);
  z-index: 1;
}

:deep(.form-container form) {
  display: flex;
  flex-direction: column;
  gap: 15px;
  height: 100%;
}
:deep(.form-container h1) {
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  color: var(--neutral800);
}
:deep(.form-container h2) {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: var(--neutral800);
}
:deep(.form-container .proceed) {
  margin: auto 0 0 0;
}
:deep(.form-container .or-line) {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 7px;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: var(--neutral800);
}
:deep(.form-container .or-line:before, .form-container .or-line:after) {
  content: '';
  height: 1px;
  width: 50px;
  background-color: var(--neutral800); 
}
:deep(.form-container .or-line:after) {
  content: '';
  height: 1px;
  width: 50px;
  background-color: var(--neutral800); 
}
:deep(.form-container button) {
  width: 100%;
}
:deep(.form-container .already) {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
}
:deep(.form-container .already span) {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: var(--neutral800);
}
</style>