export class PhoneCodeModel {
  id = '1';
  name = '+1';
  data: PhoneCodeDataModel[] = [];

  constructor(data: any = {}) {
    if (data == null) return;
    
    this.id = data.id || '1';
    this.name = data.name || '+1';
    this.data = data.data?.map((phoneCodeData: any) => new PhoneCodeDataModel(phoneCodeData)) || [];
  }
}

export class PhoneCodeDataModel {
  code =  '';
  name = '';

  constructor(data: any = {}) {
    if (data == null) return;
    
    this.code = data.code || '';
    this.name = data.name || '';
  }
}
