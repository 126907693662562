import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "marketing"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_navigation = _resolveComponent("navigation")!
  const _component_mainarea = _resolveComponent("mainarea")!
  const _component_contentarea = _resolveComponent("contentarea")!
  const _component_help = _resolveComponent("help")!
  const _component_foot = _resolveComponent("foot")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_ctx.isNonRoute)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_navigation),
        _createVNode(_component_mainarea),
        _createVNode(_component_contentarea),
        _createVNode(_component_help),
        _createVNode(_component_foot)
      ]))
    : (_openBlock(), _createBlock(_component_router_view, { key: 1 }))
}