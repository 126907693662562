<template>
  <div class="help">
    <div class="wrapper">

      <section>
        <h1>Get started with us</h1>
        <h3>Don't hesitate to get started with us! Our team is ready to assist you 24/7. Simply fill out the form on the right, and we'll help you streamline your logistics operations today.</h3>
      </section>

      <section>
        <div class="form">
          <label>Ask a question</label>
          <input-component class="input" placeholder="Full Name"  />
          <input-component class="input" placeholder="Company Email"  />
          <input-component class="input" placeholder="Company Name"  />
          <phone-component class="phone" placeholder="00000000000" />
          <select-component class="select" placeholder="Country" :items="[{ key: 1, name: 'test' }]" />
          <button class="primary large">
            <span>Talk with our team</span>
            <svg><use xlink:href="@/assets/images/sprites/common-sprite.svg#arrow-right"></use></svg>
          </button>
        </div>
      </section>
      
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import Input from '@/components/forms/Input.vue';
import Phone from '@/components/forms/Phone.vue';
import Select from '@/components/forms/Select.vue';

@Options({
  components: {
    'input-component': Input,
    'phone-component': Phone,
    'select-component': Select
  }
})

export default class Help extends Vue {}
</script>

<style scoped>
  .help {
    padding: 50px 0; 
  }
  .wrapper {
    display: flex;
    border-radius: 32px;
    background-color: #00B7C6;
    padding: 60px;
    background-image: url('/src/assets/images/marketings/help-background.svg');
    background-repeat: no-repeat;
    background-position: right;
  }
  section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 24px;
    flex: 1;
  }
  h1 {
    width: 75%;
    font-size: 72px;
    font-weight: 600;
    line-height: 120%;
    color: var(--white);
  }
  h3 {
    font-size: 20px;
    letter-spacing: 0.4px;
    line-height: 120%;
    color: var(--white);
  }
  .form {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 450px;
    margin-left: auto;
    background-color: var(--white);
    border-radius: 24px;
    padding: 32px;
  }
  .form label {
    font-size: 30px;
    font-weight: 600;
    line-height: 40px;
    letter-spacing: 0.6px;
  }
  .form button {
    border-radius: 12px;
  }
  .form button span {
    font-size: 16px;
    letter-spacing: 0.36px;
  }
  .form button svg {
    width: 24px;
    height: 24px;
    stroke: var(--white);
  }
  .form :deep(.container.input > section) {
    border-radius: 12px;
    background-color: var(--neutral100);
    padding: 10px 16px;
    border: none;
  }
  .form :deep(.container.phone > section) {
    border-radius: 12px;
    background-color: var(--neutral100);
    border: none;
    gap: 0;
    align-items: center;
  }
  .form :deep(.container.phone > section .prefix > section .selective) {
    background-color: transparent;
    border: none;
  }
  .form :deep(.container.phone > section .number > section) {
    background-color: transparent;
    border: none;
    border-left: 1px solid #EAEAEA;
    padding: 10px 16px;
  }
  .form :deep(.container.select > section) {
    border-radius: 12px;
    background-color: var(--neutral100);
    padding: 10px 0;
  }
  .form :deep(.container.select > section .selective) {
    background-color: transparent;
    border: none;
    padding: 10px 16px 10px 16px;
  }
  @media screen and (max-width: 900px) {
    .wrapper {
      flex-direction: column;
      gap: 50px;
      background-size: 200%;
      background-position: 0 center;
      padding: 30px;
    }
    h1 {
      font-size: 50px;
    }
    h3 {
      font-size: 16px;
    }
    section {
      gap: 12px;
    }
    .form {
      width: 100%;
      padding: 20px;
    }
  }
</style>