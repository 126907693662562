<template>
  <div class="faq">
    <h3>FAQ</h3>
    <div class="wrapper">

      <div class="item active">
        <input type="checkbox" id="item-1" checked>
        <label for="item-1">
          <span>What is Logictics, and how can it help my business?</span>
          <svg><use xlink:href="@/assets/images/sprites/common-sprite.svg#plus"></use></svg>
        </label>
        <span>Logictics SaaS is a comprehensive logistics management platform designed to optimize your supply chain operations. From real-time tracking to route planning and document management, it simplifies processes, reduces costs, and improves efficiency.</span>
      </div>
      
      <div class="item">
        <input type="checkbox" id="item-2">
        <label for="item-2">
          <span>Can Logictics integrate with my existing systems?</span>
          <svg><use xlink:href="@/assets/images/sprites/common-sprite.svg#plus"></use></svg>
        </label>
        <span>Logictics SaaS is a comprehensive logistics management platform designed to optimize your supply chain operations. From real-time tracking to route planning and document management, it simplifies processes, reduces costs, and improves efficiency.</span>
      </div>
      
      <div class="item">
        <input type="checkbox" id="item-3">
        <label for="item-3">
          <span>Is my data secure on Logictics?</span>
          <svg><use xlink:href="@/assets/images/sprites/common-sprite.svg#plus"></use></svg>
        </label>
        <span>Logictics SaaS is a comprehensive logistics management platform designed to optimize your supply chain operations. From real-time tracking to route planning and document management, it simplifies processes, reduces costs, and improves efficiency.</span>
      </div>
      
      <div class="item">
        <input type="checkbox" id="item-4">
        <label for="item-4">
          <span>Does Logictics support real-time shipment tracking?</span>
          <svg><use xlink:href="@/assets/images/sprites/common-sprite.svg#plus"></use></svg>
        </label>
        <span>Logictics SaaS is a comprehensive logistics management platform designed to optimize your supply chain operations. From real-time tracking to route planning and document management, it simplifies processes, reduces costs, and improves efficiency.</span>
      </div>
      
      <div class="item">
        <input type="checkbox" id="item-5">
        <label for="item-5">
          <span>What is Logictics , and how can it help my business?</span>
          <svg><use xlink:href="@/assets/images/sprites/common-sprite.svg#plus"></use></svg>
        </label>
        <span>Logictics SaaS is a comprehensive logistics management platform designed to optimize your supply chain operations. From real-time tracking to route planning and document management, it simplifies processes, reduces costs, and improves efficiency.</span>
      </div>
      <div class="item">
        <input type="checkbox" id="item-6">
        <label for="item-6">
          <span>Can I customize the platform for my business needs?</span>
          <svg><use xlink:href="@/assets/images/sprites/common-sprite.svg#plus"></use></svg>
        </label>
        <span>Logictics SaaS is a comprehensive logistics management platform designed to optimize your supply chain operations. From real-time tracking to route planning and document management, it simplifies processes, reduces costs, and improves efficiency.</span>
      </div>
      
      <div class="item">
        <input type="checkbox" id="item-7">
        <label for="item-7">
          <span>How can I get started with Logictics?</span>
          <svg><use xlink:href="@/assets/images/sprites/common-sprite.svg#plus"></use></svg>
        </label>
        <span>Logictics SaaS is a comprehensive logistics management platform designed to optimize your supply chain operations. From real-time tracking to route planning and document management, it simplifies processes, reduces costs, and improves efficiency.</span>
      </div>
      
      <div class="item">
        <input type="checkbox" id="item-8">
        <label for="item-8">
          <span>Is customer support available if I have issues?</span>
          <svg><use xlink:href="@/assets/images/sprites/common-sprite.svg#plus"></use></svg>
        </label>
        <span>Logictics SaaS is a comprehensive logistics management platform designed to optimize your supply chain operations. From real-time tracking to route planning and document management, it simplifies processes, reduces costs, and improves efficiency.</span>
      </div>

    </div>
  </div>
</template>

<script lang="ts">
import { Vue } from 'vue-class-component';
export default class Faq extends Vue {}
</script>

<style scoped>
  .faq {
    padding: 60px 0 40px 0;
  }
  h3 {
    font-size: 32px;
    font-weight: 600;
    letter-spacing: -3px;
    text-align: center;
    padding: 20px 0;
  }
  .wrapper {
    display: flex;
    flex-direction: column;
  }
  .item {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 24px;
    border-bottom: 1px solid var(--neutral400);
  }
  .item input {
    display: none;
  }
  .item label {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: -0.5px;
    cursor: pointer;
  }
  .item label svg {
    align-self: baseline;
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
    stroke: var(--neutral1000);
    stroke-width: .5px;
    margin-left: auto;
    transition: all .25s;
  }
  .item > span {
    display: none;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: -0.5px;
  }
  .item input:checked ~ label svg {
    transform: rotate(45deg);
  }
  .item input:checked ~ span {
    display: block;
  }
  @media screen and (max-width: 800px) {
    h3 {
      font-size: 28px;
    }
    .item > span {
      font-size: 14px;
      line-height: 20px;
    }
  }
</style>