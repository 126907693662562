<template>
  <div class="navigation">
    <div class="wrapper">
      <div class="logo">
        <svg><use xlink:href="@/assets/images/sprites/common-sprite.svg#app-logo-image"></use></svg>
      </div>
      <div class="nav">
        <input type="checkbox" id="menu-toggle">
        <label for="menu-toggle" class="holder">
          <svg><use xlink:href="@/assets/images/sprites/common-sprite.svg#hamburger-icon"></use></svg>
        </label>
        <div class="items">
          <a href="#">Why Logictics</a>
          <a href="#">Products</a>
          <a href="#">Use Cases</a>
          <a href="#">Pricing</a>
          <a href="#">About Us</a>
        </div>
      </div>
      <div class="app-route">
        <a :href="`//app.${baseDomain}/login`" target="_blank">Log in</a>
        <a :href="`//app.${baseDomain}/register`" target="_blank" class="started">
          <span>Get Started</span>
          <svg><use xlink:href="@/assets/images/sprites/common-sprite.svg#right-arrow-icon"></use></svg>
        </a>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

@Options({
  computed: {
    baseDomain () {
      const host = window.location.host;
      const domain = host.includes('.') ? host.split('.').slice(-2).join('.') : host;
      return domain;
    }
  }
})

export default class Navigation extends Vue {
  baseDomain!: string;
}
</script>

<style scoped>
  .navigation {
    position: relative;
    border-bottom: 1px solid var(--neutral300);
    padding: 16px 0;
  }

  .wrapper {
    display: flex;
    justify-content: space-between;
    gap: 24px;
  }

  .wrapper > div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    gap: 24px;
  }
  .wrapper > div:first-child {
    justify-content: flex-start;
  }
  .wrapper > div:last-child {
    justify-content: flex-end;
  }

  .logo svg {
    width: 45px;
    height: 45px;
  }

  /* .nav {
    position: relative;
  } */
  .nav input {
    display: none;
  }
  .nav .holder {
    display: none;
    width: 24px;
    height: 24px;
  }
  .nav .holder svg {
    width: 100%;
    height: 100%;
    stroke: var(--neutral1000);
    cursor: pointer;
  }
  .nav .items {
    display: flex;
    gap: 24px;
  }
  .nav a {
    font-size: 14px;
    line-height: 24px;
    color: var(--neutral1000);
    white-space: nowrap;
  }

  .app-route a {
    font-size: 14px;
    line-height: 24px;
    font-weight: 500;
    color: var(--neutral1000);
    white-space: nowrap;
  }
  .app-route a.started {
    display: flex;
    align-items: center;
    background-color: var(--neutral1000);
    color: var(--white);
    padding: 8px 16px;
    border-radius: 8px;
    text-decoration: none;  
  }
  .app-route a.started svg {
    height: 18px;
    width: 18px;
    stroke: var(--white);
    stroke-width: 2px;
    transition: all .25s;
  }
  .app-route a.started:hover svg {
    transform: translateX(3px);
  }
  
  @media screen and (max-width: 1024px) {
    .nav {
      order: 99;
      flex: initial !important;
    }
    .nav .holder {
      display: block;
    }
    .nav .items {
      visibility: hidden;
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      width: 100%;
      top: calc(100% + 1px);
      left: 0;
      padding: 20px;
      background-color: var(--white);
      border-bottom: 1px solid var(--neutral300);
      opacity: 0;
    }
    .nav input:checked ~ .items {
      transition: all .25s;
      visibility: visible;
      opacity: 1;
    }
  }
</style>